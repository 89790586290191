//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { oauthHeaderJson } from './../_helpers/oauth-header';
import type { filtro } from './../_types/filtro.type';
import type { paciente } from './../_types/paciente.type';
import { defaultService } from './defaultService';

export const pacienteService = {
  findByFiltro,
  doSave,
  doList,
  doGet,
  doDelete,
  findByNome,
  findByNomeOuCPF,
  findAll,
  doRegister,
};

function doRegister(paciente: paciente) {
  return defaultService.doSave(`${urlsConstants.PACIENTE_PUBLIC}`, paciente);
}

function findByFiltro(options: Options, filter: any) {
  return defaultService.findByFiltro(
    `${urlsConstants.PACIENTE}list`,
    options,
    filter
  );
}

function doSave(paciente: paciente) {
  return defaultService.doSave(`${urlsConstants.PACIENTE}`, paciente);
}

function findAll() {
  const requestOptions = {
    method: 'GET',
    headers: oauthHeaderJson(),
  };

  var p: Promise<any> = fetch(
    urlsConstants.PACIENTE_FINDALL,
    requestOptions
  ).then((response) => {
    return response.json();
  });
  return p;
}

function doList(filtro: filtro) {
  const requestOptions = {
    method: 'POST',
    headers: oauthHeaderJson(),
    body: JSON.stringify(filtro),
  };

  var p: Promise<any> = fetch(urlsConstants.PACIENTE_LIST, requestOptions).then(
    (response) => {
      return response.json();
    }
  );
  return p;
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.PACIENTE}${id}`);
}

function doDelete(id: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: oauthHeaderJson(),
  };
  var p: Promise<any> = fetch(urlsConstants.PACIENTE + id, requestOptions);
  return p;
}

function findByNome(nome: string) {
  const requestOptions = {
    method: 'GET',
    headers: oauthHeaderJson(),
  };
  var p: Promise<any> = fetch(
    urlsConstants.PACIENTE_FIND + nome,
    requestOptions
  ).then((response) => {
    return response.json();
  });
  return p;
}

function findByNomeOuCPF(nomeOuCpf: string, medico) {
  const requestOptions = {
    method: 'GET',
    headers: oauthHeaderJson(),
  };
  var p: Promise<any>;
  if (medico && medico.id) {
    p = fetch(
      `${urlsConstants.PACIENTE_FIND_NOME_CPF}${nomeOuCpf}/${medico.id}`,
      requestOptions
    ).then((response) => {
      return response.json();
    });
  } else {
    p = fetch(
      `${urlsConstants.PACIENTE_FIND_NOME_CPF}${nomeOuCpf}`,
      requestOptions
    ).then((response) => {
      return response.json();
    });
  }
  return p;
}
