//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const prontuarioService = {
  doSave,
  doGet,
  doList,
  doDelete,
  imprimir,
  findListaExames,
  findByMedicoAndPacienteHoje,
  findByMarcacao,
};
function findByMedicoAndPacienteHoje(idMedico, idPaciente) {
  return defaultService.doGet(
    `${urlsConstants.PRONTUARIO}${idMedico}/${idPaciente}`
  );
}
function doSave(prontuario: prontuario) {
  return defaultService.doSave(`${urlsConstants.PRONTUARIO}`, prontuario);
}

function doGet(prontuario: values) {
  return defaultService.doGet(`${urlsConstants.PRONTUARIO}`, prontuario);
}

function findByMarcacao(idMarcacao) {
  return defaultService.doGet(
    `${urlsConstants.PRONTUARIO}findByMarcacao/${idMarcacao}`
  );
}

function doDelete(horaFinalizou) {
  return defaultService.doDelete(`${urlsConstants.PRONTUARIO}`, horaFinalizou);
}

function doList(id: number, idEstabelecimento, isPaciente) {
  if (!idEstabelecimento) {
    idEstabelecimento = 0;
  }
  if (!isPaciente) {
    return defaultService.doPost(
      `${urlsConstants.PRONTUARIO_LIST}${id}/${idEstabelecimento}`
    );
  }
  return defaultService.doPost(
    `${urlsConstants.PRONTUARIO_LIST}paciente/${id}/${idEstabelecimento}`
  );
}

function imprimir(prontuario) {
  return defaultService.doPost(
    `${urlsConstants.PRONTUARIO_IMPRIMIR}`,
    prontuario
  );
}

function findListaExames(idPaciente: number, idEstabelecimento, id) {
  if (!idEstabelecimento) {
    idEstabelecimento = 0;
  }
  return defaultService.doPost(
    `${
      urlsConstants.PRONTUARIO_ULTIMOS_EXAMES
    }${idPaciente}/${idEstabelecimento}/${id ? id : 0}`
  );
}
