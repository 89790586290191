//@flow
//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import { FormGroup } from '../../components/FormGroup';
import FormInput from '../../components/inputs/FormInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import SelectInput from '../../components/inputs/SelectInput';
import { translate } from '../../_helpers/messages.helper';
import { defaultService } from '../../_services/defaultService';
import { exameService } from '../../_services/exame.service';
import { procedimentoService } from '../../_services/procedimento.service';
import MoneyInput from "../../components/inputs/MoneyInput";

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};

const ExameValidate = Yup.object().shape({
  nome: Yup.string()
    .min(3, 'Nome muito curto!')
    .max(120, 'Nome muito grande!')
    .required('Obrigatório'),
});

class ExameForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  goToViewEntity = (values) => {
    const { redirectPath } = this.props;
    this.props.history.push({
      pathname: redirectPath + values.id,
      state: { entity: values },
    });
  };

  saveForm = () => {
    let _this = this;
    let values = _this.formRef.state.values;
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          console.log(values);
          exameService.doSave(values).then(
            (response) => {
              _this.props.success({
                message: `Exame, foi ${
                  !values.id ? 'criado' : 'alterado'
                } com sucesso!`,
              });
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;
              _this.props.loading(false);
              _this.goToViewEntity(values);
              if (_this.props.user.id === values.id) {
                values.role = _this.props.user.role;
                _this.props.refreshUser(values);
              }
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar exame.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };

  addItemInArray = (propName, defaultValue, values, setValues) => {
    let newValues = _.cloneDeep(values);
    newValues[propName].push(
      defaultValue ? defaultValue : { idList: new Date().getTime() }
    );
    setValues(newValues);
  };

  removeItemInArray = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName].length > 1) {
      newValues[propName].splice(indexItem, 1);
      setValues(newValues);
    }
  };
  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  render() {
    let _this = this;
    const { viewMode, entity, propName, index } = this.props;
    return (
      <React.Fragment>
        <Formik
          validationSchema={ExameValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                <div className="row section-form">
                  <div className="col-12 col-lg-12 form-row pl-3">
                    <FormGroup className="col-12 col-lg-5">
                      <InputViewEdit
                        component={FormInput}
                        label={'Nome'}
                        type="text"
                        id="nome"
                        name="nome"
                        placeholder="Digite o nome do exame"
                        value={values.nome}
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        erroMensagem={errors.nome}
                        viewMode={viewMode}
                        required={true}
                        viewClassName="font-weight-bold"
                      />
                    </FormGroup>
                    <FormGroup className="col-12 col-lg-5">
                      <InputViewEdit
                        component={SelectInput}
                        label={'Procedimento'}
                        id="procedimento"
                        name="procedimento"
                        placeholder="Procedimento"
                        value={_.get(values, 'procedimento')}
                        defaultValue={
                          _.get(values, 'procedimento')
                            ? _.get(values, 'procedimento').descricaoCodigo
                            : '--'
                        }
                        onChange={setFieldValue}
                        noSize={true}
                        erroMensagem={_.get(errors, 'procedimento')}
                        viewMode={viewMode}
                        required={true}
                        returnFullObject={true}
                        valueKey={'id'}
                        labelKey={'descricaoCodigo'}
                        multi={false}
                        onFetchData={procedimentoService.findAll}
                        sortKey={'descricao '}
                      />
                    </FormGroup>
                    <FormGroup className="col-12  col-lg-2">
                      <InputViewEdit
                          component={MoneyInput}
                          type="text"
                          name="valor"
                          label="Valor do examer"
                          placeholder="Informe o valor do exame"
                          onChange={setFieldValue}
                          noSize={true}
                          viewMode={viewMode}
                          id="valorConsultaParticular"
                          value={values.valor}
                          erroMensagem={errors.valor}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(ExameForm);
