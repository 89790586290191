//@flow
import _, { isArray } from 'lodash';
import { objectsConstants } from '../_constants/objects.constants';
import { CacheAgenda } from '../_components/CacheAgenda';

export function filterMarcacoes(marcacoes?: any, date: any) {
  if (!marcacoes) {
    return false;
  }

  if (!date) {
    return false;
  }

  return marcacoes.find((marcacao) => {
    let dataConsulta = new Date(marcacao.dataConsulta + ' 00:00:00');
    return dataConsulta.getTime() === date.getTime();
  });
}

export function getAgendasDayPacienteMedico(
  agendas: any,
  date?: any,
  paciente: paciente,
  medico: medico
) {
  if (!agendas || !agendas.length || !date) {
    return [];
  }
  const agendaDay = getAgendasDay(agendas, date);

  let agendaDayPaciente = [];

  _.filter(agendaDay, function (agenda) {
    if (
      agenda.medico.id === medico.id &&
      agenda.paciente &&
      agenda.paciente.id === paciente.id
    ) {
      agendaDayPaciente.push(agenda);
    }
    _.forEach(agenda.listaEncaixe, function (encaixe) {
      if (
        encaixe.medico.id === medico.id &&
        encaixe.paciente &&
        encaixe.paciente.id === paciente.id
      ) {
        agendaDayPaciente.push(encaixe);
      }
    });
  });

  return agendaDayPaciente;

  // let agendaDayPaciente = _.filter(agendaDay, function (agenda) {
  //   return (
  //     agenda.medico.id === medico.id &&
  //     agenda.paciente &&
  //     agenda.paciente.id === paciente.id
  //   );
  // });
  // return agendaDayPaciente;
}

export function getAgendasDay(agendas: any, date?: any) {
  if (!agendas || !agendas.length || !date) {
    return [];
  }
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  if (currentDate.getTime() > date.getTime()) {
    return [];
  }
  let dataString = date.toLocaleDateString('pt-br');
  let agendaDay = _.filter(agendas, { data: dataString });
  return agendaDay;
}
export function updateFila(oldFila, newPacienteFila) {
  let fila = _.cloneDeep(oldFila);
  if (fila) {
    if (newPacienteFila.id) {
      let pacienteEncontrado = false;
      fila = _.map(fila, (f) => {
        if (f.id == newPacienteFila.id) {
          pacienteEncontrado = true;
          return newPacienteFila;
        } else {
          return f;
        }
      });
      if (!pacienteEncontrado) {
        fila.push(newPacienteFila);
      }
    }
  }
  return fila;
}

export function updateCache(newCache, oldCache) {
  let oldCacheCopy = _.cloneDeep(oldCache);
  for (let newItemServerCache of newCache) {
    let newItemCache = new CacheAgenda(newItemServerCache.filtro);
    newItemCache.agenda = newItemServerCache.agenda;
    let oldItemCache = newItemCache.exist(oldCacheCopy);
    if (oldItemCache) {
      let index = _.findIndex(oldCacheCopy, oldItemCache);
      oldCacheCopy.splice(index, 1, newItemCache);
    }
  }
  return oldCacheCopy;
}
export function updateMarcacao(
  agendas?: any,
  marcacaoUpdate?: any,
  tipoAgenda
) {
  if (!agendas) {
    return [];
  }

  if (!marcacaoUpdate) {
    return agendas;
  }

  if (marcacaoUpdate.length) {
    let listReturn = [];
    marcacaoUpdate.forEach((item, index) => {
      listReturn = _.concat(
        listReturn,
        iterateMarcacao(agendas, item, tipoAgenda)
      );
    });
    return listReturn;
  } else {
    return iterateMarcacao(agendas, marcacaoUpdate, tipoAgenda);
  }
}

//utiliza o algoritimo de busca binaria para encontrar a marcacao; necessario a agenda estar ordenada

function findMarcacao(
  agenda,
  dataProcurada,
  horaProcurada,
  idMedicoProcurado,
  idExameProcurado,
  idEstabelecimentoProcurado,
  nomeExame,
  cancelado
) {
  let inicio = 0;
  let fim = agenda.length - 1;

  while (inicio <= fim) {
    let meio = Math.floor((inicio + fim) / 2);
    let marcacao = agenda[meio];
    console.log(marcacao);
    let idMedico = _.get(marcacao, 'medico.id');
    let idExame = _.get(marcacao, 'exame.id');
    // Compara a data do registro com a data procurada
    if (marcacao.data < dataProcurada) {
      inicio = meio + 1;
    } else if (marcacao.data > dataProcurada) {
      fim = meio - 1;
    } else {
      // Se a data for igual, compara a hora do registro com a hora procurada
      if (marcacao.horario < horaProcurada) {
        inicio = meio + 1;
      } else if (marcacao.horario > horaProcurada) {
        fim = meio - 1;
      } else {
        // Se a hora for igual, compara o idMedico do registro com o idMedico procurado
        if (
          (idMedico && idMedico < idMedicoProcurado) ||
          (idExame && idExame < idExameProcurado)
        ) {
          inicio = meio + 1;
        } else if (
          (idMedico && idMedico > idMedicoProcurado) ||
          (idExame && idExame > idExameProcurado)
        ) {
          fim = meio - 1;
        } else {
          if (
            (idMedico && idMedico === idMedicoProcurado) ||
            (idExame && idExame === idExameProcurado)
          ) {
            let verificaPaciente =
              idMedico && !idExame && idMedicoProcurado && idExameProcurado;
            if (!verificaPaciente || !marcacao.paciente || cancelado) {
              return meio;
            } else {
              _.get(marcacao, 'medico.id');
              let idExame = _.get(marcacao, 'exame.id');
              agenda.splice(meio, 0, {
                horario: horaProcurada,
                data: dataProcurada,
                medico: _.get(marcacao, 'medico'),
                exame: { id: idExameProcurado, nome: nomeExame },
                listaEncaixe: [],
                estabelecimento: _.get(marcacao, 'estabelecimento'),
              });
              return meio;
            } // registro encontrado
          } else {
            return null;
          }
        }
      }
    }
  }

  return null; // registro não encontrado
}

export function mesmoMesAno(data1, data2) {
  // Converter strings em objetos Date
  try {
    let date1 = new Date(data1);
    let date2 = new Date(data2.split('/').reverse().join('/'));

    // Comparar mês e ano dos objetos Date
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth()
    );
  } catch (e) {
    return false;
  }
}

function findMarcacaoEstabelecimentoAtendimento(
  agenda,
  dataProcurada,
  horaProcurada,
  idEstabelecimentoProcurado
) {
  let inicio = 0;
  let fim = agenda.length - 1;

  while (inicio <= fim) {
    let meio = Math.floor((inicio + fim) / 2);
    let marcacao = agenda[meio];
    let idEstabelecimento = _.get(marcacao, 'estabelecimentoAtendimento.id');
    // Compara a data do registro com a data procurada
    if (marcacao.data < dataProcurada) {
      inicio = meio + 1;
    } else if (marcacao.data > dataProcurada) {
      fim = meio - 1;
    } else {
      // Se a data for igual, compara a hora do registro com a hora procurada
      if (marcacao.horario < horaProcurada) {
        inicio = meio + 1;
      } else if (marcacao.horario > horaProcurada) {
        fim = meio - 1;
      } else {
        // Se a hora for igual, compara o idMedico do registro com o idMedico procurado
        if (idEstabelecimento < idEstabelecimentoProcurado) {
          inicio = meio + 1;
        } else if (idEstabelecimento > idEstabelecimentoProcurado) {
          fim = meio - 1;
        } else {
          return meio; // registro encontrado
        }
      }
    }
  }
  return null;
}

export function iterateMarcacao(
  agendas?: any,
  marcacaoUpdate?: any,
  tipoAgenda
) {
  let marcacoes = agendas.slice();
  if (marcacaoUpdate.horarioReservadoObj) {
    let indexReservado = findMarcacaoEstabelecimentoAtendimento(
      marcacoes,
      marcacaoUpdate.horarioReservadoObj.data,
      marcacaoUpdate.horarioReservadoObj.hora,
      marcacaoUpdate.horarioReservadoObj.estabelecimentoAtendimento.id
    );

    let marcacaoReservado = _.cloneDeep(marcacoes[indexReservado]);
    if (marcacaoUpdate.horarioReservado && marcacaoReservado) {
      marcacaoReservado.idMarcacao = null;
      marcacaoReservado.paciente = null;
      marcacaoReservado.status = 'RESERVADO';
      marcacaoReservado.horarioReservado = true;
      marcacaoReservado.horarioReservadoObj =
        marcacaoUpdate.horarioReservadoObj;
    } else {
      if (marcacaoReservado) {
        marcacaoReservado.horarioReservado = false;
      }
    }

    if (marcacaoReservado) {
      marcacoes[indexReservado] = marcacaoReservado;
    }

    return marcacoes;
  }
  let index = findMarcacao(
    marcacoes,
    marcacaoUpdate.dataConsulta,
    marcacaoUpdate.listMarcacaoHora[0].hora,
    marcacaoUpdate.fkMedico,
    marcacaoUpdate.fkExame,
    marcacaoUpdate.fkEstabelecimento,
    marcacaoUpdate.nomeExame,
    marcacaoUpdate.listMarcacaoHora[0].status === 'CANCELADO' ||
      marcacaoUpdate.listMarcacaoHora[0].status === 'REAGENDADA'
  );
  if (
    !marcacaoUpdate.horarioReservado &&
    !marcacaoUpdate.horarioReservadoObj &&
    (marcacaoUpdate.listMarcacaoHora[0].status === 'CANCELADO' ||
      marcacaoUpdate.listMarcacaoHora[0].status === 'REAGENDADA') &&
    marcacaoUpdate.tipo === objectsConstants.TIPO_MARCACAO &&
    (index || index === 0)
  ) {
    if (
      marcacaoUpdate.fkMedico &&
      marcacaoUpdate.fkExame &&
      tipoAgenda &&
      tipoAgenda === objectsConstants.TIPO_AGENDA_CONSULTAS &&
      marcacoes.length > index + 1 &&
      marcacoes[index + 1].horario === marcacoes[index].horario &&
      marcacoes[index + 1].medico.id === marcacaoUpdate.fkMedico
    ) {
      marcacoes.splice(index, 1);
      return marcacoes;
    }
    marcacoes[index].idMarcacao = null;
    marcacoes[index].paciente = null;
    marcacoes[index].status = null;
    return marcacoes;
  }
  let marcacao;
  if (index || index === 0) {
    marcacao = _.cloneDeep(marcacoes[index]);
  }

  if (
    !marcacaoUpdate.horarioReservado &&
    !marcacaoUpdate.horarioReservadoObj &&
    marcacao
  ) {
    if (marcacaoUpdate.tipo === objectsConstants.TIPO_MARCACAO) {
      marcacao.idMarcacao = marcacaoUpdate.id;
      marcacao.paciente = marcacaoUpdate.listMarcacaoHora[0].paciente;
      marcacao.status = marcacaoUpdate.listMarcacaoHora[0].status;
      marcacao.telemedicina = marcacaoUpdate.telemedicina;
      marcacao.tipoConsulta = marcacaoUpdate.tipoConsulta;
      _.set(
        marcacao,
        'medico.localAtendimento',
        _.get(marcacaoUpdate, 'medico.localAtendimento')
      );

      marcacao.tipoAtendimento = marcacaoUpdate.tipoAtendimento;
      marcacao.convenio = marcacaoUpdate.convenio;
      marcacao.planoConvenio = marcacaoUpdate.planoConvenio;
      marcacao.nomeSala = marcacaoUpdate.nomeSala;
      marcacao.localEtapaAtendimento = marcacaoUpdate.localEtapaAtendimento;
      marcacao.urlSalaPaciente = marcacaoUpdate.urlSalaPaciente;
      marcacao.observacoes = marcacaoUpdate.observacoes;
      marcacao.especialidade = marcacaoUpdate.especialidade;
      marcacao.horarioReservado = false;
      //_.set(marcacao, 'listaEncaixe', marcacaoUpdate.listaEncaixe);
      if (_.get(marcacaoUpdate, 'fkExame')) {
        marcacao.medico = marcacaoUpdate.medico;
      }
    } else if (marcacaoUpdate.tipo === objectsConstants.TIPO_ENCAIXE) {
      let encaixe = _.cloneDeep(marcacao);
      _.set(encaixe, 'idMarcacao', marcacaoUpdate.id);
      _.set(encaixe, 'paciente', marcacaoUpdate.listMarcacaoHora[0].paciente);
      _.set(
        encaixe,
        'localEtapaAtendimento',
        marcacaoUpdate.localEtapaAtendimento
      );
      _.set(encaixe, 'status', marcacaoUpdate.listMarcacaoHora[0].status);
      _.set(encaixe, 'telemedicina', marcacaoUpdate.telemedicina);
      _.set(encaixe, 'tipoConsulta', marcacaoUpdate.tipoConsulta);
      _.set(encaixe, 'horaChegada', marcacaoUpdate.horaChegada);
      _.set(encaixe, 'tipoAtendimento', marcacaoUpdate.tipoAtendimento);
      _.set(encaixe, 'convenio', marcacaoUpdate.convenio);
      _.set(encaixe, 'planoConvenio', marcacaoUpdate.planoConvenio);
      _.set(encaixe, 'nomeSala', marcacaoUpdate.nomeSala);
      _.set(encaixe, 'urlSalaPaciente', marcacaoUpdate.urlSalaPaciente);
      _.set(encaixe, 'observacoes', marcacaoUpdate.observacoes);
      _.set(encaixe, 'especialidade', marcacaoUpdate.especialidade);
      _.set(encaixe, 'listaEncaixe', []);
      if (_.get(marcacaoUpdate, 'fkExame')) {
        _.set(encaixe, 'medico', marcacaoUpdate.medico);
      }

      var indexEncaixe = _.findIndex(marcacao.listaEncaixe, {
        idMarcacao: encaixe.idMarcacao,
      });
      var indexEncaixeSemPaciente = _.findIndex(
        marcacao.listaEncaixe,
        function (o) {
          if (
            !o.paciente ||
            (encaixe.paciente &&
              o.paciente.id === encaixe.paciente.id &&
              !o.idMarcacao)
          ) {
            return true;
          }
          return false;
        }
      );
      if (indexEncaixeSemPaciente >= 0) {
        marcacao.listaEncaixe.splice(indexEncaixeSemPaciente, 1);
      }
      if (indexEncaixe < 0) {
        //if (_.get(marcacao, 'paciente')) {
        marcacao.listaEncaixe.push(encaixe);
        //}
      } else {
        if (encaixe.status === 'CANCELADO') {
          marcacao.listaEncaixe.splice(indexEncaixe, 1);
        } else {
          marcacao.listaEncaixe[indexEncaixe] = encaixe;
        }
      }
    }
  }

  if (marcacao) {
    marcacoes[index] = marcacao;
  }

  return marcacoes;
}

export function updateMarcacaoAgenda(agendas?: any, marcacaoUpdate?: any) {
  if (!agendas) {
    return [];
  }

  if (!marcacaoUpdate) {
    return agendas;
  }

  if (marcacaoUpdate.length) {
    let listReturn = [];
    marcacaoUpdate.forEach((item, index) => {
      listReturn = _.concat(listReturn, iterateMarcacaoAgenda(agendas, item));
    });
    return listReturn;
  } else {
    return iterateMarcacaoAgenda(agendas, marcacaoUpdate);
  }
}

export function iterateMarcacaoAgenda(agendas?: any, marcacaoUpdate?: any) {
  let marcacoes = _.cloneDeep(agendas);
  marcacoes.forEach((h, index) => {
    if (
      !marcacaoUpdate.horarioReservado &&
      h.data === marcacaoUpdate.data &&
      h.horario === marcacaoUpdate.horario &&
      ((h.medico &&
        marcacaoUpdate.medico &&
        _.get(h, 'medico.id', '') === _.get(marcacaoUpdate, 'medico.id', '')) ||
        (h.exame &&
          marcacaoUpdate.exame &&
          _.get(h, 'exame.id', '') ===
            _.get(marcacaoUpdate, 'exame.id', ''))) &&
      _.get(h, 'estabelecimento.id') ===
        _.get(marcacaoUpdate, 'estabelecimento.id')
    ) {
      if (marcacaoUpdate.tipo === objectsConstants.TIPO_MARCACAO) {
        marcacoes[index].idMarcacao = marcacaoUpdate.idMarcacao;
        marcacoes[index].paciente = marcacaoUpdate.paciente;
        marcacoes[index].status = marcacaoUpdate.status;
        marcacoes[index].telemedicina = marcacaoUpdate.telemedicina;
        marcacoes[index].tipoConsulta = marcacaoUpdate.tipoConsulta;
        marcacoes[index].tipoAtendimento = marcacaoUpdate.tipoAtendimento;
        marcacoes[index].convenio = marcacaoUpdate.convenio;
        marcacoes[index].planoConvenio = marcacaoUpdate.planoConvenio;
        marcacoes[index].nomeSala = marcacaoUpdate.nomeSala;
        marcacoes[index].urlSalaPaciente = marcacaoUpdate.urlSalaPaciente;
        marcacoes[index].observacoes = marcacaoUpdate.observacoes;
        marcacoes[index].especialidade = marcacaoUpdate.especialidade;
        _.set(marcacoes[index], 'listaEncaixe', marcacaoUpdate.listaEncaixe);
        if (_.get(marcacaoUpdate, 'exame')) {
          marcacoes[index].medico = marcacaoUpdate.medico;
        }
      } else if (marcacaoUpdate.tipo === objectsConstants.TIPO_ENCAIXE) {
        let encaixe = _.cloneDeep(marcacoes[index]);
        _.set(encaixe, 'idMarcacao', marcacaoUpdate.idMarcacao);
        _.set(encaixe, 'paciente', marcacaoUpdate.paciente);
        _.set(encaixe, 'status', marcacaoUpdate.status);
        _.set(encaixe, 'telemedicina', marcacaoUpdate.telemedicina);
        _.set(encaixe, 'tipoConsulta', marcacaoUpdate.tipoConsulta);
        _.set(encaixe, 'tipoAtendimento', marcacaoUpdate.tipoAtendimento);
        _.set(encaixe, 'nomeSala', marcacaoUpdate.nomeSala);
        _.set(encaixe, 'convenio', marcacaoUpdate.convenio);
        _.set(encaixe, 'planoConvenio', marcacaoUpdate.planoConvenio);
        _.set(encaixe, 'urlSalaPaciente', marcacaoUpdate.urlSalaPaciente);
        _.set(encaixe, 'observacoes', marcacaoUpdate.observacoes);
        _.set(encaixe, 'especialidade', marcacaoUpdate.especialidade);
        _.set(encaixe, 'listaEncaixe', []);
        if (_.get(marcacaoUpdate, 'exame')) {
          _.set(encaixe, 'medico', marcacaoUpdate.medico);
        }
        let marcacao = _.cloneDeep(marcacoes[index]);

        var indexEncaixe = _.findIndex(marcacao.listaEncaixe, {
          idMarcacao: encaixe.idMarcacao,
        });
        if (indexEncaixe < 0) {
          if (_.get(marcacoes[index], 'paciente')) {
            marcacao.listaEncaixe.push(encaixe);
          }
        } else {
          if (encaixe.status === 'CANCELADO') {
            marcacao.listaEncaixe.splice(indexEncaixe, 1);
          } else {
            marcacao.listaEncaixe[indexEncaixe] = encaixe;
          }
        }
        marcacoes[index] = marcacao;
      }
    }
  });

  return marcacoes;
}

function _removeMarcacao(marcacoes: any, marcacaoUpdate: any) {
  marcacoes.forEach((h, index) => {
    if (
      h.data === marcacaoUpdate.dataConsulta &&
      h.horario === marcacaoUpdate.listMarcacaoHora[0].hora &&
      (_.get(h, 'medico.id', '') === marcacaoUpdate.fkMedico ||
        _.get(h, 'exame.id', '') === marcacaoUpdate.fkExame)
    ) {
      // if (h.listaEncaixe.length < 1) {
      marcacoes[index].idMarcacao = null;
      marcacoes[index].paciente = null;
      marcacoes[index].status = null;
      // } /*else {
      /* let primeiroEncaixe = _.cloneDeep(h.listaEncaixe[0]);
        _.set(primeiroEncaixe, 'tipo', objectsConstants.TIPO_MARCACAO);
        marcacoes[index].listaEncaixe.splice(0, 1);
        _.set(primeiroEncaixe, 'listaEncaixe', marcacoes[index].listaEncaixe);
        marcacoes[index] = primeiroEncaixe;
      }*!/*/
    }
  });
}
