import _, { cloneDeep } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { objectsConstants } from '../../_constants/objects.constants';
import { translate } from '../../_helpers/messages.helper';
import { agendaService } from '../../_services/agenda.service';
import { marcacaoService } from '../../_services/marcacao.service';
import { medicoService } from '../../_services/medico.service';
import { userService } from '../../_services/user.service';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { ModalCrud } from '../../components/Modal/ModalCrud';
import MarcacaoForm from '../marcacao/MarcacaoForm';
import './AgendamentoRapido.css';
import AgendamentoRapidoPainel from './AgendamentoRapidoPainel';
import FiltroAgendamento from './FiltroAgendamento';
import { dateHelper } from '../../_helpers/date.helper';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';

const DEFAULT_FILTER = {
  cache: [],
  agendaSemBuild: [],
  indexMedico: 0,
  medico: null,
  filtro: { data: new Date() },
  listaMedico: [],
  agenda: null,
  manha: true,
  tarde: true,
  noite: true,
  showNewPaciente: false,
  paciente: null,
  dataFinalHorarios: new Date(),
};

const defaultPaciente = {
  nome: '',
  email: '',
  // cpfCnpj: '',
  // especialidade: null,
  telefones: [{}],
  tipoAtendimento: objectsConstants.TIPO_ATENDIMENTO_PARTICULAR,
  pacienteConvenios: [
    {
      convenio: {},
      plano: '',
      carteirinha: '',
      validadeCarteirinha: '',
    },
  ],
};
class AgendamentoRapidoPage extends React.Component<{}> {
  constructor(props) {
    super(props);
    this.state = DEFAULT_FILTER;
  }

  changePeriodo = (periodo) => {
    let newState = _.cloneDeep(this.state);
    _.set(newState, periodo, !_.get(newState, periodo));
    this.setState(newState);
  };

  componentDidMount() {
    this.carregaDadosInicias();
    this.getCamposObrigatorios();
  }

  carregaDadosInicias() {
    this.props.loading(true);
    agendaService.buscaHorariosDisponiveis(this.state.filtro).then(
      (r) => {
        let agenda = this.buildAgenda(r.data);
        this.setState({
          agendaSemBuild: r.data,
          cache: agenda,
          indexMedico: 0,
          listaMedico: agenda,
        });
        this.props.loading(false);
      },
      (error) => {
        console.log(error);
        this.props.error({
          message:
            'Não foi possível buscar horários disponíveis tente mais tarde!',
        });
        this.props.loading(false);
      }
    );
  }

  getCamposObrigatorios = () => {
    let idEstabelecimento = userService.getExtension();

    if (idEstabelecimento) {
      estabelecimentoService
        .findCamposObrigatorios(idEstabelecimento)
        .then((response) => {
          console.log(response);
          let camposObrigatorios = response.data;
          this.setState({ camposObrigatorios: camposObrigatorios });
        });
    }
  };

  horariosProximoMes = () => {
    this.props.loading(true);
    let agenda = _.cloneDeep(this.state.agendaSemBuild);
    let indexMedico = _.cloneDeep(this.state.indexMedico);
    let filtro = _.cloneDeep(DEFAULT_FILTER);
    let proximoMes = dateHelper.adicionaMes(this.state.dataFinalHorarios);
    _.set(filtro, 'data', proximoMes);

    agendaService.buscaHorariosDisponiveis(filtro).then(
      (r) => {
        let novaAgendaSemBuild = _.union(agenda, r.data);
        let novaAgenda = this.buildAgenda(novaAgendaSemBuild);
        this.setState(
          {
            agendaSemBuild: novaAgendaSemBuild,
            cache: novaAgenda,
            indexMedico: indexMedico,
            listaMedico: novaAgenda,
            dataFinalHorarios: proximoMes,
          },
          () => {
            this.pesquisar();
            this.props.loading(false);
          }
        );
      },
      (error) => {
        console.log(error);
        this.props.error({
          message:
            'Não foi possível buscar horários disponíveis tente mais tarde!',
        });
        this.props.loading(false);
      }
    );
  };

  filtroChange = (name, value) => {
    let filtro = _.cloneDeep(this.state.filtro);
    _.set(filtro, name, value);
    this.setState({ filtro });
  };

  buildAgenda = (agenda) => {
    let goupByIdMedico = _.groupBy(agenda, function (a) {
      return _.get(a, 'medico.id');
    });
    let medicos = [];
    Object.entries(goupByIdMedico).map(([idMedico, listHorarios]) => {
      let medico = { medico: listHorarios[0].medico };

      let dias = _.groupBy(listHorarios, function (a) {
        return _.get(a, 'data');
      });
      medico.agenda = [];
      Object.entries(dias).map(([dia, listHorarios]) => {
        listHorarios = _.orderBy(
          listHorarios,
          (h) => {
            return moment(h.horario, 'hh:mm');
          },
          ['asc']
        );
        medico.agenda.push({ dia, horariosDisponiveis: listHorarios });
      });
      medico.agenda = _.orderBy(
        medico.agenda,
        (h) => {
          return moment(h.dia, 'DD/MM/YYYY');
        },
        ['asc']
      );
      medicos.push(medico);
    });

    return medicos;
  };

  pesquisar = () => {
    const { filtro, cache } = this.state;
    let agenda = [];
    if (filtro) {
      agenda = _.concat(
        agenda,
        _.filter(cache, (r) => {
          return this.filter(_.get(r, 'medico'));
        })
      );
    }

    this.setState({ listaMedico: agenda }, () => {
      console.log('setou nova lista de medicos');
    });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.marcacao &&
      (nextProps.marcacao.listMarcacaoHora ||
        nextProps.marcacao.horarioReservadoObj)
    ) {
      let listaMedico = _.cloneDeep(this.state.listaMedico);
      let cache = _.cloneDeep(this.state.cache);
      this.atualizaAgenda(nextProps, listaMedico);
      this.atualizaAgenda(nextProps, cache);
      //console.log(nextProps.marcacao.listMarcacaoHora);
    }
  }

  atualizaAgenda = (nextProps, listaMedico) => {
    if (_.get(nextProps, 'marcacao.medico')) {
      _.map(listaMedico, (medico) => {
        if (medico.medico.id === nextProps.marcacao.medico.id) {
          _.map(medico.agenda, (agenda) => {
            if (nextProps.marcacao.dataConsulta === agenda.dia) {
              if (
                nextProps.marcacao.listMarcacaoHora[0].status !== 'CANCELADO'
              ) {
                let indexRemove = -1;
                _.map(agenda.horariosDisponiveis, (horario, index) => {
                  console.log(horario);
                  if (
                    horario.horario ===
                    nextProps.marcacao.listMarcacaoHora[0].hora
                  ) {
                    indexRemove = index;
                  }
                });

                if (indexRemove >= 0) {
                  agenda.horariosDisponiveis.splice(indexRemove, 1);
                }
              } else {
                if (
                  _.findIndex(agenda.horariosDisponiveis, (h) => {
                    return (
                      h.horario ===
                        nextProps.marcacao.listMarcacaoHora[0].hora &&
                      nextProps.marcacao.dataConsulta === h.data
                    );
                  }) === -1
                ) {
                  agenda.horariosDisponiveis.push({
                    medico: nextProps.marcacao.medico,
                    estabelecimento: {
                      id: nextProps.marcacao.fkEstabelecimento,
                    },
                    data: nextProps.marcacao.dataConsulta,
                    horario: nextProps.marcacao.listMarcacaoHora[0].hora,
                    estabelecimentoAtendimento: {
                      id: nextProps.marcacao.fkEstabelecimentoAtendimento,
                    },
                  });
                }
              }
            }
          });
        }
      });
      this.setState({ listaMedico, cache: listaMedico }, () => {
        console.log('setou state');
      });
    } else if (_.get(nextProps, 'marcacao.horarioReservadoObj')) {
      _.map(listaMedico, (medico) => {
        if (medico.medico.id === nextProps.marcacao.fkMedico) {
          _.map(medico.agenda, (agenda) => {
            if (nextProps.marcacao.horarioReservadoObj.data === agenda.dia) {
              if (nextProps.marcacao.horarioReservado) {
                let indexRemove = -1;
                _.map(agenda.horariosDisponiveis, (horario, index) => {
                  if (
                    horario.horario ===
                    nextProps.marcacao.horarioReservadoObj.hora
                  ) {
                    indexRemove = index;
                  }
                });
                if (indexRemove >= 0) {
                  agenda.horariosDisponiveis.splice(indexRemove, 1);
                }
              } else {
                if (
                  _.findIndex(agenda.horariosDisponiveis, (h) => {
                    return (
                      h.horario ===
                        nextProps.marcacao.horarioReservadoObj.hora &&
                      nextProps.marcacao.horarioReservadoObj.data === h.data
                    );
                  }) === -1
                ) {
                  agenda.horariosDisponiveis.push({
                    medico: { id: nextProps.marcacao.fkMedico },
                    estabelecimento: {
                      id: nextProps.marcacao.fkEstabelecimento,
                    },
                    data: nextProps.marcacao.horarioReservadoObj.data,
                    horario: nextProps.marcacao.horarioReservadoObj.hora,
                    estabelecimentoAtendimento: {
                      id: nextProps.marcacao.fkEstabelecimentoAtendimento,
                    },
                  });
                }
              }
            }
          });
        }
      });
      this.setState({ listaMedico, cache: listaMedico }, () => {
        console.log('setou state');
      });
    }
  };

  filter = (medico) => {
    const { filtro } = this.state;
    return (
      this.validateConvenio(medico, filtro.convenio) &&
      this.validatePlano(medico, filtro.plano) &&
      this.validateEspecialidade(medico, filtro.especialidade) &&
      this.validateMedico(medico, filtro.profissional)
    );
  };
  validateEspecialidade = (medico, especialidade) => {
    return (
      !especialidade ||
      _.findIndex(_.get(medico, 'especialidades'), (e) => {
        return e.id === especialidade.id;
      }) >= 0
    );
  };

  validateMedico = (medico, profissionalFiltro) => {
    return (
      !profissionalFiltro ||
      _.get(profissionalFiltro, 'id') === _.get(medico, 'id')
    );
  };

  validateConvenio = (medico, convenio) => {
    return (
      !convenio ||
      _.findIndex(_.get(medico, 'listConvenio'), (e) => {
        return e.id === convenio.id;
      }) >= 0
    );
  };

  validatePlano = (medico, plano) => {
    let planoEncontrado = false;
    _.map(_.get(medico, 'listConvenio'), (e) => {
      if (
        _.findIndex(_.get(e, 'listPlanoConvenio'), (p) => {
          return plano && p.id === plano.id;
        }) >= 0
      ) {
        planoEncontrado = true;
      }
    });
    return !plano || planoEncontrado;
  };

  limparFiltro = () => {
    this.setState({ filtro: {} });
  };

  filtroAgenda = () => {
    const { manha, tarde, noite, indexMedico, listaMedico } = this.state;
    if (listaMedico && listaMedico.length > 0) {
      let agenda;
      if (listaMedico.length == 1) {
        agenda = listaMedico[0].agenda;
      } else {
        agenda = listaMedico[indexMedico].agenda;
      }
      let agendaFiltrada = _.map(agenda, (horario) => {
        let newHorario = _.cloneDeep(horario);
        newHorario.horariosDisponiveis = [];
        if (manha) {
          newHorario.horariosDisponiveis = _.filter(
            horario.horariosDisponiveis,
            (hd) => {
              let horarioInicio = new Date(
                '1970-01-01T' + objectsConstants.FILTRO_PERIODO.manha.inicio
              );
              let horarioFim = new Date(
                '1970-01-01T' + objectsConstants.FILTRO_PERIODO.manha.fim
              );
              let horarioAtual = new Date('1970-01-01T' + hd.horario);
              return (
                horarioInicio.getTime() <= horarioAtual.getTime() &&
                horarioFim.getTime() >= horarioAtual.getTime()
              );
            }
          );
        }
        if (tarde) {
          newHorario.horariosDisponiveis =
            newHorario.horariosDisponiveis.concat(
              _.filter(horario.horariosDisponiveis, (hd) => {
                let horarioInicio = new Date(
                  '1970-01-01T' + objectsConstants.FILTRO_PERIODO.tarde.inicio
                );
                let horarioFim = new Date(
                  '1970-01-01T' + objectsConstants.FILTRO_PERIODO.tarde.fim
                );
                let horarioAtual = new Date('1970-01-01T' + hd.horario);
                return (
                  horarioInicio.getTime() <= horarioAtual.getTime() &&
                  horarioFim.getTime() >= horarioAtual.getTime()
                );
              })
            );
        }
        if (noite) {
          newHorario.horariosDisponiveis =
            newHorario.horariosDisponiveis.concat(
              _.filter(horario.horariosDisponiveis, (hd) => {
                let horarioInicio = new Date(
                  '1970-01-01T' + objectsConstants.FILTRO_PERIODO.noite.inicio
                );
                let horarioFim = new Date(
                  '1970-01-02T' + objectsConstants.FILTRO_PERIODO.noite.fim
                );
                let horarioAtual = new Date('1970-01-01T' + hd.horario);

                return (
                  horarioInicio.getTime() <= horarioAtual.getTime() &&
                  horarioFim.getTime() >= horarioAtual.getTime()
                );
              })
            );
        }
        if (newHorario.horariosDisponiveis.length > 0) {
          return newHorario;
        }
      });
      return agendaFiltrada;
    }
    return [];
  };

  handleNewOptionClick = (nome: any, tipo, medico, agenda) => {
    let paciente = defaultPaciente;
    paciente.nome = nome;
    paciente.primeiraConsulta = true;
    paciente.horario = agenda.horario;
    paciente.tipo = tipo;
    paciente.especialidade = null;
    _.set(paciente, 'camposObrigatorios', this.state.camposObrigatorios[0]);

    this.setState({
      paciente,
      showNewPaciente: true,
      agenda: agenda,
      medico: medico,
    });
  };

  tooglePacientes = (paciente, medico, agenda) => {
    if (!paciente || !medico || !agenda) {
      this.setState({
        showNewPaciente: !this.state.showNewPaciente,
        paciente: null,
        agenda: null,
        medico: null,
      });
    } else {
      this.props.loading(true);
      let data = moment(agenda.data, 'DD/MM/YYYY');
      marcacaoService
        .consultaHoje(agenda.estabelecimentoAtendimento.id, paciente.id, data)
        .then(
          (response) => {
            let temConsultahoje = response.data;
            if (temConsultahoje) {
              this.props.warning({
                message: `Já existe uma marcação para ${_.get(
                  paciente,
                  'nome'
                )} com ${_.get(medico, 'apelido')} no dia de hoje`,
              });
              this.props.loading(false);
            }

            marcacaoService
              .primeiraConsulta(
                agenda.estabelecimentoAtendimento.id,
                paciente.id,
                data,
                agenda.horario
              )
              .then(
                (response) => {
                  let primeiraConsulta = response.data.primeiraConsulta;
                  let primeiraConsultaDisabled = response.data.disable;
                  let limiteDiarioAtingido = response.data.limiteDiarioAtingido;
                  if (primeiraConsulta && limiteDiarioAtingido) {
                    this.props.error({
                      message:
                        'Não foi possível realizar o agendamento, para PRIMEIRA CONSULTA entre em contato com a clínica',
                    });
                    this.props.loading(false);
                  } else {
                    userService.doGet(paciente.id).then(
                      (response) => {
                        let tipoConsulta = _.get(paciente, 'tipoConsulta');
                        let tipo = _.get(paciente, 'tipo');
                        let newPaciente = response.data.data;
                        _.set(newPaciente, 'tipoConsulta', tipoConsulta);
                        _.set(
                          newPaciente,
                          'primeiraConsulta',
                          primeiraConsulta
                        );
                        _.set(
                          newPaciente,
                          'primeiraConsultaDisabled',
                          primeiraConsultaDisabled
                        );

                        _.set(newPaciente, 'horario', agenda.horario);
                        _.set(newPaciente, 'atendeTelemedicina', false);
                        _.set(newPaciente, 'tipo', tipo);
                        if (
                          !_.get(medico, 'especialidades') ||
                          _.get(medico, 'especialidades').length === 0
                        ) {
                          medicoService.doGet(medico.id).then((r) => {
                            this.setState({
                              showNewPaciente: !this.state.showNewPaciente,
                              paciente: newPaciente,
                              agenda,
                              medico: r.data,
                            });
                            this.props.loading(false);
                          });
                        } else {
                          this.setState({
                            showNewPaciente: !this.state.showNewPaciente,
                            paciente: newPaciente,
                            agenda,
                            medico,
                          });
                          // _.set(newPaciente, 'dataConsulta', this.props.date);

                          this.props.loading(false);
                        }
                      },
                      (error) => {
                        console.log(error);
                        this.props.loading(false);
                      }
                    );
                  }
                },
                (error) => {
                  console.log(error);
                  this.props.loading(false);
                }
              );
          },
          (error) => {
            console.log(error);
            this.props.loading(false);
          }
        );
    }
  };
  changeMedico = (indexMedico) => {
    this.setState({ indexMedico });
  };
  handleMarcacaoPaciente = (paciente) => {
    const { medico, agenda, horaNewPaciente } = this.state;
    console.log(paciente.medico);
    console.log(medico);
    this.doSaveMarcacao(
      paciente,
      agenda.horario,
      paciente.medico ? paciente.medico : medico,
      agenda.estabelecimentoAtendimento
    );
  };

  doSaveMarcacao = (
    paciente: paciente,
    horaString: string,
    medico: medico,
    estabelecimentoAtendimento: estabelecimentoAtendimento
  ) => {
    let { agenda } = this.state;
    let data = moment(agenda.data, 'DD/MM/YYYY');
    let dataConsulta = moment(data);
    let fkCovenio = null;
    let planoConvenio = null;
    if (
      _.get(paciente, 'tipoAtendimento') ===
      objectsConstants.TIPO_ATENDIMENTO_CONVENIO
    ) {
      fkCovenio = _.get(paciente, 'pacienteConvenios[0].convenio.id');
      planoConvenio = _.get(paciente, 'pacienteConvenios[0].planoConvenio');
    }

    let tipoConsulta = _.get(paciente, 'tipoConsulta');
    let observacoes = _.get(paciente, 'observacoes');
    let tipo = objectsConstants.TIPO_MARCACAO;
    marcacaoService
      .doSave({
        horaString,
        dataConsulta: dataConsulta.format('YYYY-MM-DD'),
        fkPaciente: paciente.id,
        fkMedico: _.get(medico, 'id', _.get(paciente, 'medico.id')),
        fkEstabelecimentoAtendimento: estabelecimentoAtendimento.id,
        tipoAtendimento: paciente.tipoAtendimento,
        fkCovenio,
        paciente,
        telemedicina: paciente.telemedicina,
        tipoConsulta,
        observacoes,
        tipo,
        planoConvenio,
        especialidade: paciente.especialidade,
      })
      .then(
        (response) => {
          this.props.success({
            message: `Paciente agendado com sucesso!`,
          });
          this.setState({
            showNewPaciente: !this.state.showNewPaciente,
            paciente: null,
            agenda: null,
            medico: null,
          });
        },
        (erros) => {
          try {
            let response = erros.response.data;
            if (response && response.messages) {
              for (var i = 0; i < response.messages.length; i++) {
                let erroItem = response.messages[i];
                this.props.error({
                  message: translate(erroItem.message.code),
                });
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      );
  };

  render() {
    return (
      <>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_AGENDAMENTO_RAPIDO}
          subTitle=" "
          canBack={false}
        />
        <FiltroAgendamento
          pesquisar={this.pesquisar}
          filtro={this.state.filtro}
          onChange={this.filtroChange}
          limpar={this.limparFiltro}
        />

        <div className="agendamento-container mt-1 mt-sm-4">
          <AgendamentoRapidoPainel
            changeMedico={this.changeMedico}
            listaMedico={this.state.listaMedico}
            agenda={this.filtroAgenda()}
            manha={this.state.manha}
            tarde={this.state.tarde}
            noite={this.state.noite}
            changePeriodo={this.changePeriodo}
            tooglePacientes={this.tooglePacientes}
            onNewOptionClick={this.handleNewOptionClick}
            indexMedico={this.state.indexMedico}
          />
        </div>
        {this.filtroAgenda().length > 0 && (
          <div className="my-4 d-flex align-items-center justify-content-center">
            <button
              className="btn btn-primary col-6 col-sm-3 col-md-2"
              onClick={this.horariosProximoMes}
            >
              Próximos 30 dias
            </button>
          </div>
        )}
        {this.state.agenda && (
          <ModalCrud
            title="Agendar paciente"
            toogle={this.tooglePacientes}
            isOpen={this.state.showNewPaciente}
            Componente={MarcacaoForm}
            agendamentoMode={true}
            pacienteOnline={false}
            viewMode={false}
            entity={this.state.paciente}
            callbackOnSave={this.handleMarcacaoPaciente}
            onCancel={this.cancelShowNewPaciente}
            estabelecimento={this.state.agenda.estabelecimento}
            titleBtnSave={'Agendar'}
            hideAddres={true}
            hideCarteirinha={true}
            medico={this.state.medico}
            estabelecimentoAtendimento={
              this.state.agenda.estabelecimentoAtendimento
            }
            tipoAgenda={objectsConstants.TIPO_AGENDA_CONSULTAS}
            dataConsulta={moment(this.state.agenda.data, 'DD/MM/YYYY').toDate()}
            permissionSave={objectsConstants.AGENDA_MARCAR}
          ></ModalCrud>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { marcacao } = state.agenda;
  return {
    marcacao,
  };
}
const mapDispatch = ({
  load: { loading },
  alert: { error, warning, success },
}) => ({
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  warning: (msg) => warning(msg),
  success: (msg) => success(msg),
});

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(AgendamentoRapidoPage));
