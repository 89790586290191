//@flow
import moment from 'moment';
import * as React from 'react';
import _ from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import ButtonAutorized from '../../components/buttons/ButtonAutorized';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import CheeseburgerMenu from '../../components/menu/CheeseburgerMenu';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../components/Topo/TopoListagem';
import { objectsConstants } from '../../_constants/objects.constants';
import { agendaService } from '../../_services/agenda.service';
import { pacienteService } from '../../_services/paciente.service';
import { prontuarioService } from '../../_services/prontuario.service';
import { userService } from '../../_services/user.service';
import Historico from '../Historico/Historico';
import AtendimentosList from './atendimentos/AtendimentosList';
import PacienteForm from './PacienteForm';
import { marcacaoService } from '../../_services/marcacao.service';

const defaultArrayItem = [{ idList: new Date().getTime() }];

class PacienteView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true },
      listAtendimentos: {},
      listAgenda: {},
      listAgendaExames: {},
      listHistoricoExames: {},
      mes:
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.mes
          ? this.props.location.state.mes
          : {},
      ano:
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.ano
          ? this.props.location.state.ano
          : {},
    };
  }

  componentDidMount() {
    this.loadPaciente();
  }
  componentWillUnmount() {
    this.props.fechaHistorico();
  }

  loadPaciente = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      pacienteService.doGet(this.props.match.params.id).then(
        (response) => {
          let paciente = response.data.data;
          this.setState({ entity: paciente }, () => {
            this.loadListAnamnese();
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };

  loadAgendaPaciente = () => {
    this.props.loading(true);
    let filtro = {};
    let dataStart = new Date();
    let dataEnd = new Date().setDate(dataStart.getDate() + 30);
    filtro.paciente = { id: this.props.match.params.id };
    agendaService.findMarcacaoByPaciente(filtro).then(
      (json) => {
        this.setState({ listAgenda: json.data }, () => {
          this.props.loading(false);
        });
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );

    agendaService.findMarcacaoExamesByPaciente(filtro).then(
      (json) => {
        console.log(json.data);
        this.setState({ listAgendaExames: json.data }, () => {
          this.props.loading(false);
        });
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );
  };

  loadListAnamnese = () => {
    this.props.loading(true);
    let filtro = {};
    let dataStart = new Date();
    let dataEnd = new Date().setDate(dataStart.getDate() + 30);
    filtro.paciente = { id: this.props.match.params.id };
    Promise.all([
      marcacaoService.findHistoricoMarcacaoByPaciente(filtro),
      marcacaoService.findHistoricoMarcacaoExameByPaciente(filtro),
    ]).then(
      (response) => {
        let listHistoricoAtendimento = response[0].data;
        let listHistoricoExames = response[1].data;
        this.setState(
          {
            listAtendimentos: listHistoricoAtendimento,
            listHistoricoExames: listHistoricoExames,
          },
          () => {
            this.loadAgendaPaciente();
          }
        );
      },
      (error) => {
        console.log(error);
        this.loadAgendaPaciente();
        this.props.loading(false);
      }
    );
  };

  abreProntuario = (idPaciente) => {
    this.props.history.push('/prontuario/' + idPaciente);
  };

  getBtns = () => {
    if (userService.isMedico() || userService.isSuporte()) {
      return (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            this.abreProntuario(this.state.entity.id);
          }}
          role={objectsConstants.CRUD_ANAMNESE_VISUALIZAR}
        >
          Ver prontuário
        </button>
      );
    }
  };
  delete = () => {
    pacienteService.doDelete(this.state.entity.id).then((r) => {
      this.props.success({
        message: `Paciente foi excluído com sucesso!`,
      });
      this.props.loading(false);
      this.props.history.push('/paciente');
    });
  };
  render() {
    const {
      entity,
      listAgenda,
      listAtendimentos,
      listAgendaExames,
      listHistoricoExames,
    } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        {entity && entity.id && (
          <React.Fragment>
            <TopoTitleComponente mainTitle={entity.nome} canBack={true} />

            <TopoListagem
              showIndisponibilidade={false}
              showfilter={false}
            ></TopoListagem>
            {/* <ButtonAutorized
              className="btn-secondary open"
              label={'Histórico de alterações'}
              role={objectsConstants.AGENDA_MARCAR}
              onClick={() => {
                console.log('Abre histórico');
                this.props.abreHistorico(entity);
              }}
            ></ButtonAutorized> */}
            <CheeseburgerMenu
              isOpen={this.props.historicoAberto}
              closeCallback={this.props.fechaHistorico}
              right={true}
              width={400}
              className="historico-bg"
            >
              {this.props.historicoAberto && <Historico />}
            </CheeseburgerMenu>
            <div className="flex-column">
              <FullCustomCard
                permissionSave={objectsConstants.MENU_PACIENTE}
                title="Paciente"
                Componente={PacienteForm}
                btnExcluir={!entity.temMarcacao}
                removeItem={this.delete}
                viewMode={true}
                entity={entity}
                hideAddres={true}
                cancelForm={this.loadUser}
                onSave={this.loadUser}
                extraButton={this.getBtns}
                exibeHistorico={true}
                abreHistoricoCard={() => {
                  console.log(_.get(entity, 'id'));
                  this.props.receiveIdEntityHistorico(
                    _.get(entity, 'id'),
                    objectsConstants.TIPO_HISTORICO_PACIENTE
                  );
                  this.props.abreHistorico();
                }}
                {...otherPops}
              ></FullCustomCard>
              {listAgenda.length > 0 && (
                <FullCustomCard
                  permissionSave={objectsConstants.MENU_PACIENTE}
                  title="Marcações"
                  className="mt-100"
                  Componente={AtendimentosList}
                  viewMode={true}
                  entity={entity}
                  agenda={listAgenda}
                  atendimentos={[]}
                  getBtns={() => {}}
                  online={false}
                  {...otherPops}
                ></FullCustomCard>
              )}
              {listAgendaExames.length > 0 && (
                <FullCustomCard
                  permissionSave={objectsConstants.MENU_PACIENTE}
                  title="Exames"
                  className="mt-100"
                  Componente={AtendimentosList}
                  viewMode={true}
                  entity={entity}
                  agenda={listAgendaExames}
                  atendimentos={[]}
                  getBtns={() => {}}
                  online={false}
                  {...otherPops}
                ></FullCustomCard>
              )}
              {listAtendimentos.length > 0 && (
                <FullCustomCard
                  permissionSave={objectsConstants.MENU_PACIENTE}
                  title="Histórico de consultas"
                  className="mt-100"
                  Componente={AtendimentosList}
                  viewMode={true}
                  entity={entity}
                  agenda={[]}
                  atendimentos={listAtendimentos}
                  getBtns={() => {}}
                  online={false}
                  {...otherPops}
                ></FullCustomCard>
              )}
              {listHistoricoExames.length > 0 && (
                <FullCustomCard
                  permissionSave={objectsConstants.MENU_PACIENTE}
                  title="Histórico de exames"
                  className="mt-100"
                  Componente={AtendimentosList}
                  viewMode={true}
                  entity={entity}
                  agenda={[]}
                  atendimentos={listHistoricoExames}
                  getBtns={() => {}}
                  online={false}
                  {...otherPops}
                ></FullCustomCard>
              )}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { historicoAberto } = state.historicomenu;
  return {
    historicoAberto,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  historicomenu: {
    toggleHistorico,
    abreHistorico,
    fechaHistorico,
    receiveIdEntityHistorico,
  },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  toggleHistorico: () => toggleHistorico(),
  abreHistorico: () => abreHistorico({}),
  fechaHistorico: () => fechaHistorico(),
  receiveIdEntityHistorico: (idEntityHistorico, tipoHistorico) =>
    receiveIdEntityHistorico({ idEntityHistorico, tipoHistorico }),
});

export default withRouter(connect(mapStateToProps, mapDispatch)(PacienteView));
