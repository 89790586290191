//@flow
//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactHtmlParser from 'react-html-parser';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import { FormGroup } from '../../components/FormGroup';
import FormInput from '../../components/inputs/FormInput';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import FormTextCKEditor from '../../components/inputs/FormTextCKEditor';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import imagemBG from '../../images/bgImage.jpeg';
import { translate } from '../../_helpers/messages.helper';
import { defaultService } from '../../_services/defaultService';
import { medicoService } from '../../_services/medico.service';
import { midiaService } from '../../_services/midia.service';
import { modeloReceituarioService } from '../../_services/modeloReceituario.service';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};

const ModelosReceituarioValidate = Yup.object().shape({
  nome: Yup.string()
    .min(3, 'Nome muito curto!')
    .max(120, 'Nome muito grande!')
    .required('Campo obrigatório'),
  listMedicos: Yup.string().required('Campo obrigatório'),
  prescricao: Yup.string().required('Campo obrigatório'),
});

class ModelosReceituarioForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      todosProfissionais: false,
    };
  }

  goToViewEntity = (values) => {
    const { redirectPath } = this.props;
    this.props.history.push({
      pathname: redirectPath + values.id,
      state: { entity: values },
    });
  };

  saveForm = () => {
    //  console.log('ele nao e o pai');
    let _this = this;
    let values = _this.formRef.state.values;
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        //  console.log(error);

        if (_.isEmpty(error)) {
          _this.props.loading(true);
          modeloReceituarioService.doSave(values).then(
            (response) => {
              _this.props.success({
                message: `Modelos, foi ${
                  !values.id ? 'criado' : 'alterado'
                } com sucesso!`,
              });

              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;

              _this.props.loading(false);
              _this.goToViewEntity(values);
              if (_this.props.user.id === values.id) {
                values.role = _this.props.user.role;
                _this.props.refreshUser(values);
              }
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar modelo.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };

  addItemInArray = (propName, defaultValue, values, setValues) => {
    let newValues = _.cloneDeep(values);
    newValues[propName].push(defaultValue ? defaultValue : {});
    setValues(newValues);
  };

  removeItemInArray = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    newValues[propName].splice(indexItem, 1);
    setValues(newValues);
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  imageUrl = (valuesAttr) => {
    if (valuesAttr && valuesAttr.id) {
      return midiaService.url(valuesAttr.id);
    } else {
      if (valuesAttr) {
        return valuesAttr.midiaConteudoVO.foto64;
      } else {
        return imagemBG;
      }
    }
  };
  // assintauraUrl = (values) => {
  //   if (values.assinatura && values.assinatura.id) {
  //     return midiaService.url(values.assinatura.id);
  //   } else {
  //     if (values.assinatura) {
  //       return values.assinatura.midiaConteudoVO.foto64;
  //     } else {
  //       return imagemBG;
  //     }
  //   }
  // };
  // logoUrl = (values) => {
  //   if (values.logo && values.logo.id) {
  //     return midiaService.url(values.logo.id);
  //   } else {
  //     if (values.logo) {
  //       return values.logo.midiaConteudoVO.foto64;
  //     } else {
  //       return imagemBG;
  //     }
  //   }
  // };

  // backgroundImageUrl = (values) => {
  //   if (values.backgroundImage && values.backgroundImage.id) {
  //     return midiaService.url(values.backgroundImage.id);
  //   } else {
  //     if (values.backgroundImage) {
  //       return values.backgroundImage.midiaConteudoVO.foto64;
  //     } else {
  //       return imagemBG;
  //     }
  //   }
  // };

  selecionarTodosProfissionais = () => {
    this.setState({ todosProfissionais: !this.state.todosProfissionais });
  };

  doGetCaretPosition = (ctrl) => {
    var CaretPos = 0;
    // IE Support
    if (document.selection) {
      ctrl.focus();
      var Sel = document.selection.createRange();

      Sel.moveStart('character', -ctrl.value.length);

      CaretPos = Sel.text.length;
    }
    // Firefox support
    else if (ctrl.selectionStart || ctrl.selectionStart == '0')
      CaretPos = ctrl.selectionStart;

    return CaretPos;
  };
  insertAtCaret = (event, areaId, text, values, setFieldValue) => {
    event.preventDefault();
    let position;
    let newText;
    if (values[areaId] && values[areaId].length >= 4) {
      position = values[areaId].length - 4;

      newText = [
        values[areaId].slice(0, position),
        text,
        values[areaId].slice(position),
      ].join('');
    } else {
      newText = text;
    }

    setFieldValue(areaId, newText);
  };

  render() {
    let _this = this;
    const { viewMode, entity } = this.props;
    console.log(_.get(entity, 'listMedico', []));
    return (
      <Formik
        validationSchema={ModelosReceituarioValidate}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={entity}
        ref={(form) => {
          this.formRef = form;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          validationSchema,
          validateForm,
          setValues,
        }) => (
          <form onSubmit={handleSubmit}>
            <h2 className="w-100">Disponibilidade</h2>
            <div className="form-inside">
              <div className="row section-form">
                <React.Fragment>
                  <FormGroup className=" col-10">
                    {!this.state.todosProfissionais && (
                      <InputViewEdit
                        component={FormSelectInput}
                        name="listMedicos"
                        id={'listMedicos'}
                        label="Selecione um ou mais profissionais de saúde"
                        placeholder="Selecione um ou mais profissionais de saúde"
                        valueKey="id"
                        labelKey="nome"
                        noSize={true}
                        required={true}
                        returnFullObject={true}
                        erroMensagem={errors.listMedicos}
                        isMulti={true}
                        searchable={true}
                        allowSelectAll={true}
                        viewMode={viewMode}
                        service={medicoService.findMedicoByEspecialidade}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        value={
                          !viewMode
                            ? _.get(values, `listMedicos`)
                            : _.get(values, 'listMedicos', [])
                                .map((e) => e.apelido)
                                .join(', ')
                        }
                      />
                    )}
                  </FormGroup>
                </React.Fragment>
              </div>
            </div>
            <h2 className="w-100">Informações</h2>
            <div className="form-inside">
              <div className="row section-form">
                <FormGroup className="col-12 col-lg-3">
                  <InputViewEdit
                    component={FormInput}
                    label={'Nome'}
                    type="text"
                    id="nome"
                    name="nome"
                    placeholder="Digite o nome do modelo"
                    value={values.nome}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.nome}
                    viewMode={viewMode}
                    required={true}
                    viewClassName="font-weight-bold"
                  />
                </FormGroup>
              </div>
            </div>
            <h2 className="w-100">Modelo do Receituarro</h2>
            <div className="form-inside">
              <div className="row section-form">
                <FormGroup className="col-12 col-lg-10">
                  {!viewMode && (
                    <FormTextCKEditor
                      label="Modelo receituario"
                      id={'prescricao'}
                      name={'prescricao'}
                      className={'textCKeditor'}
                      type={'textarea'}
                      required={true}
                      onChange={setFieldValue}
                      erroMensagem={errors.prescricao}
                      viewMode={viewMode}
                      viewClassName={'view-anamnese'}
                      value={values.prescricao}
                      defaultValue={values.prescricao}
                      placeholder="Insira aqui as informções do modelo de receituario"
                      removePlugins={[
                        'Heading',
                        'BlockQuote',
                        'ImageUpload',
                        'MediaEmbed',
                      ]}
                      ref={(ckRef) => {
                        this.refCkEditor = ckRef;
                      }}
                    />
                  )}
                  {viewMode && <label>Recomendações</label>}
                  {viewMode && (
                    <p className="form-view">
                      {ReactHtmlParser(values.prescricao)}
                    </p>
                  )}
                </FormGroup>
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(ModelosReceituarioForm);
