import _ from 'lodash';
import React, { PureComponent } from 'react';
import connect from 'react-redux/es/connect/connect';
import { FormGroup, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { objectsConstants } from '../../_constants/objects.constants';
import { modeloService } from '../../_services/modelo.service';
import print from '../../images/icon-print.svg';
import CheckBoxInputOnly from '../inputs/CheckBoxInputOnly';
import FormSelectInput from '../inputs/FormSelectInput';
import FormTextCKEditor from '../inputs/FormTextCKEditor';
import InputViewEdit from '../inputs/InputViewEdit';
import { ModalSolutiOtp } from './ModalSolutiPasswordOtp';

type Props = {
  target?: any,
  entity: any,
  callbackOnSave: any,
};
type State = {
  receituario: [],
};

class ModalImpressao extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      parteFinal: false,
      receituario: [],
      modeloDocumento: {},
      showCorpoModelo: false,
      openSolutiOtp: false,
      generateAndSendEmail: false,
    };
  }

  toogleParte = () => {
    this.setState({
      parteFinal: !this.state.parteFinal,
      modelo: {},
      textoCorpoEditado: '',
      modeloDocumento: null,
      showCorpoModelo: false,
    });
  };

  cancelar = () => {
    this.setState({ showCorpoModelo: false }, () => {
      this.props.toogle();
    });
  };

  componentDidMount() {
    this.selecionaTodos(this.props.entity.listReceituarioItem);
  }

  selecionaTodos = (itens) => {
    let newItens = _.cloneDeep(itens);
    _.forEach(newItens, function (item) {
      _.set(item, 'imprimir', true);
    });
    this.setState({ receituario: newItens });
  };

  selecionaImprimir = (checked, id) => {
    let newItens = _.cloneDeep(this.state.receituario);
    _.forEach(newItens, function (item) {
      if (_.isEqual(item.id, id)) {
        _.set(item, 'imprimir', checked);
      }
    });
    this.setState({ receituario: newItens });
  };

  // getLableImpresso = (item) => {
  //   if (item.impresso) {
  //     return (
  //       <div className="d-flex align-items-center justify-content-center flex-column">
  //         <div className="flag-impresso">Impresso</div>
  //         <div className="data">{item.dataImpressao}</div>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div className="d-flex align-items-center flag-nao-impresso">
  //         Não foi impresso
  //       </div>
  //     );
  //   }
  // };

  // segunda parte da impressão
  changeText = (text) => {
    this.setState({
      textoCorpoEditado: text,
    });
  };
  selecionouModelo = (modelo) => {
    const { marcacao } = this.props;
    let documentoPrint = {};
    let newItens = _.cloneDeep(this.state.receituario);
    _.remove(newItens, function (item) {
      return !item.imprimir;
    });
    _.set(documentoPrint, 'marcacaoVO', marcacao);
    _.set(documentoPrint, 'documentoVO', modelo);
    _.set(documentoPrint, 'listReceituarioItem', newItens);
    modeloService.textoModeloSubstituido(documentoPrint).then(
      (response) => {
        let textoCorpoEditado = response.data;
        this.setState({
          showCorpoModelo: true,
          textoCorpoEditado: textoCorpoEditado,
          modeloDocumento: modelo,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  handleSetOtpCode = (otpCode) => {
    if (otpCode) {
      if (!/^[0-9]+$/.test(otpCode)) {
        this.props.error({
          message: 'O código OTP são somente números',
        });
      } else {
        this.props.entity.documentSignVO = {
          otpCode: otpCode,
          signDocument: true,
        };
        this.toggleSolutiOtp();
        if (this.state.generateAndSendEmail) {
          this.generateAndSendMail();
        } else {
          this.imprimir();
        }
      }
    } else {
      this.props.error({
        message: 'Insira o seu código otp',
      });
    }
  };

  signAndSendEmail = () => {
    this.setState({ generateAndSendEmail: true });
    let modeloDocumento = _.cloneDeep(this.state.modeloDocumento);
    if (_.isEmpty(modeloDocumento)) {
      this.props.loading(false);
      this.props.error({
        message: 'Selecione um tipo de documento',
      });
    } else {
      if (this.props.marcacao.medico.signerAuthenticationIsValid) {
        this.props.entity.documentSignVO = { signDocument: true };
        this.generateAndSendMail();
      } else {
        this.props.loading(false);
        this.toggleSolutiOtp();
      }
    }
  };

  signAndPrint = () => {
    this.setState({ generateAndSendEmail: false });
    let modeloDocumento = _.cloneDeep(this.state.modeloDocumento);
    if (_.isEmpty(modeloDocumento)) {
      this.props.loading(false);
      this.props.error({
        message: 'Selecione um tipo de documento',
      });
    } else {
      if (this.props.marcacao.medico.signerAuthenticationIsValid) {
        this.props.entity.documentSignVO = { signDocument: true };
        this.imprimir();
      } else {
        this.props.loading(false);
        this.toggleSolutiOtp();
      }
    }
  };

  imprimir = () => {
    this.setState({ generateAndSendEmail: false });
    this.props.loading(true);
    let newTextoCorpoEditado = this.state.textoCorpoEditado;
    // let newItens = _.cloneDeep(this.state.receituario);
    let modeloDocumento = _.cloneDeep(this.state.modeloDocumento);
    // _.remove(newItens, function (item) {
    //   return !item.imprimir;
    // });
    if (_.isEmpty(modeloDocumento)) {
      this.props.loading(false);
      this.props.error({
        message: 'Selecione um tipo de documento',
      });
    } else {
      let oldReceituario = _.cloneDeep(this.state.receituario);
      let listaPrint = _.cloneDeep(this.props.entity);
      // _.set(listaPrint, 'listReceituarioItem', newItens);
      _.set(listaPrint, 'marcacaoVO', this.props.marcacao);
      _.set(listaPrint, 'documentoVO', modeloDocumento);
      _.set(
        _.get(listaPrint, 'documentoVO'),
        'textoCorpo',
        newTextoCorpoEditado
      );
      modeloService.imprimir(listaPrint).then(
        (response) => {
          this.props.callbackOnSave(this.props.entity);
          var base64 = _.get(response, 'data.data.data');
          let asciiString = atob(base64);
          let array = new Uint8Array(
            [...asciiString].map((char) => char.charCodeAt(0))
          );
          const file = new Blob([array], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          this.props.toogle();
          window.open(fileURL);
          this.props.loading(false);
          this.props.marcacao.medico.signerAuthenticationIsValid = this.props
            .entity.documentSignVO
            ? this.props.entity.documentSignVO.signDocument
            : false;
        },
        (error) => {
          this.treatSignerSignException(error);
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };

  generateAndSendMail = () => {
    this.props.loading(true);
    let newTextoCorpoEditado = this.state.textoCorpoEditado;
    let modeloDocumento = _.cloneDeep(this.state.modeloDocumento);
    let listaPrint = _.cloneDeep(this.props.entity);
    _.set(listaPrint, 'marcacaoVO', this.props.marcacao);
    _.set(listaPrint, 'documentoVO', modeloDocumento);
    _.set(_.get(listaPrint, 'documentoVO'), 'textoCorpo', newTextoCorpoEditado);
    modeloService.generateDocumentAndSendEmail(listaPrint).then(
      (response) => {
        this.props.loading(false);
        this.props.success({
          message:
            'Documento enviado com sucesso por e-mail. Por favor aguarde em até no máximo 1 (uma) hora para receber o e-mail.',
        });
        this.props.marcacao.medico.signerAuthenticationIsValid = this.props
          .entity.documentSignVO
          ? this.props.entity.documentSignVO.signDocument
          : false;
        this.cancelar();
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
        this.treatSignerSignException(error);
      }
    );
  };

  toggleSolutiOtp = () => {
    this.setState(({ openSolutiOtp }) => ({ openSolutiOtp: !openSolutiOtp }));
  };

  treatSignerSignException = (error) => {
    if (
      this.props.entity.documentSignVO.signDocument &&
      error &&
      error.response &&
      error.response.data
    ) {
      this.props.error({
        message: this.treatSignerSignExceptionGetMessage(error.response.data),
      });
    }
  };

  treatSignerSignExceptionGetMessage = (data) => {
    if ('9001' === data.errorCode) {
      return 'Não foi possível assinar o documento por falta de informações. Complete seu cadastro completo para assinar o documento corretamente. (Endereço, Telefone, Conselho).';
    } else if ('9002' === data.errorCode) {
      return 'Seu código OTP não confere. Por favor valide corretamente o mesmo, junto com seu tempo de expiração e tente novamente.';
    } else {
      return 'Erro desconhecido ao assinar o seu documento. Por favor tente novamente em instantes, caso o erro persista entre em contato com o administrador do sistema.';
    }
  };

  render() {
    const { title, ...otherProps } = this.props;
    const { receituario, showCorpoModelo, textoCorpoEditado, parteFinal } =
      this.state;

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          backdrop="static"
          // size="lg"
          modalClassName=""
          className="modal-impressao modal-xl"
          centered={true}
          target={this.props.target}
        >
          <ModalBody className="min-heigth-modal">
            <div className="row top-modal">
              <div className="col-12 text-center">
                <img className="" src={print} alt="anexo" height="50" />
              </div>
              <div className="col-12 text-center my-2">
                <h2 className="font-weight-bold">Imprimir receituário</h2>
              </div>
            </div>
            {parteFinal && (
              <React.Fragment>
                <div className="col-12">
                  <InputViewEdit
                    component={FormSelectInput}
                    label={'Selecione o modelo do documento'}
                    id="modelo"
                    name="modelo"
                    placeholder="Selecione um modelo"
                    value={_.get(this.state, 'modeloDocumento')}
                    onChange={(name, value) => this.selecionouModelo(value)}
                    noSize={true}
                    required={true}
                    service={modeloService.findReceituario}
                    labelKey={'nomeListagem'}
                    valueKey={'id'}
                    returnFullObject={true}
                  />
                </div>
                <FormGroup className="col-12 col-lg-12">
                  {showCorpoModelo && (
                    <FormTextCKEditor
                      label="Corpo da mensagem"
                      id={'textoCorpoEditado'}
                      name={'textoCorpoEditado'}
                      className={'textCKeditor'}
                      type={'textarea'}
                      required={true}
                      onChange={(name, value) => this.changeText(value)}
                      viewClassName={'view-anamnese'}
                      value={textoCorpoEditado}
                      defaultValue={textoCorpoEditado}
                      placeholder="Insira aqui as informções do modelo"
                      removePlugins={[
                        'Heading',
                        'BlockQuote',
                        'ImageUpload',
                        'MediaEmbed',
                      ]}
                    />
                  )}
                </FormGroup>
              </React.Fragment>
            )}
            {!parteFinal && (
              <div className="row">
                {receituario &&
                  receituario.map((receituarioItem, index) => {
                    return (
                      <React.Fragment
                        key={
                          receituarioItem && receituarioItem.id
                            ? receituarioItem.id
                            : index
                        }
                      >
                        <div
                          className="w-100 d-flex flex-row align-items-start"
                          id={receituarioItem.id}
                        >
                          <CheckBoxInputOnly
                            checked={receituarioItem.imprimir}
                            name={`imprimir`}
                            onChange={(checked, name) => {
                              this.selecionaImprimir(
                                checked,
                                receituarioItem.id
                              );
                            }}
                          ></CheckBoxInputOnly>

                          {receituarioItem.type ===
                            objectsConstants.TIPO_MEDICAMENTO && (
                            <div className="d-flex flex-row w-100">
                              <div className="d-flex flex-column flex-fill my-3">
                                <p className="nome">
                                  {_.get(receituarioItem, 'nome')
                                    ? _.get(receituarioItem, 'nome')
                                    : _.get(
                                        receituarioItem,
                                        'medicamento.nome'
                                      )}
                                </p>
                                <p className="posologia">
                                  {_.get(
                                    receituarioItem,
                                    'posologia.descricao'
                                  )}
                                </p>
                              </div>
                              {/* {this.getLableImpresso(receituarioItem)} */}
                            </div>
                          )}
                          {receituarioItem.type ===
                            objectsConstants.TIPO_FORMULA && (
                            <div className="d-flex flex-row w-100">
                              <div className="d-flex flex-column flex-fill my-3">
                                <p className="nome">
                                  {receituarioItem.formula}
                                </p>
                                <p className="posologia">
                                  {_.get(
                                    receituarioItem,
                                    'posologia.descricao'
                                  )}
                                </p>
                              </div>
                              {/* {this.getLableImpresso(receituarioItem)} */}
                            </div>
                          )}
                          {receituarioItem.type ===
                            objectsConstants.TIPO_EXAMES_OUTROS && (
                            <div className="d-flex flex-row w-100">
                              <div className="d-flex flex-column flex-fill my-3">
                                <p className="nome">
                                  {receituarioItem.prescricao}
                                </p>
                              </div>
                              {/* {this.getLableImpresso(receituarioItem)} */}
                            </div>
                          )}
                        </div>
                        <hr></hr>
                      </React.Fragment>
                    );
                  })}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <React.Fragment>
              <div className="w-50 text-center">
                {!parteFinal && (
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      this.cancelar();
                    }}
                  >
                    {' '}
                    Cancelar{' '}
                  </button>
                )}
                {parteFinal && (
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      this.toogleParte();
                    }}
                  >
                    {' '}
                    Voltar{' '}
                  </button>
                )}
              </div>
              <div className="w-50 text-center">
                {parteFinal && (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.imprimir();
                    }}
                  >
                    {' '}
                    Imprimir{' '}
                  </button>
                )}
                {parteFinal && this.props.marcacao.medico && (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.signAndPrint();
                    }}
                  >
                    {' '}
                    Assinar e Imprimir{' '}
                  </button>
                )}
                {parteFinal &&
                  this.props.marcacao.medico &&
                  this.props.marcacao.paciente.email && (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        this.signAndSendEmail();
                      }}
                    >
                      {' '}
                      Assinar e enviar por e-mail{' '}
                    </button>
                  )}
                {parteFinal && this.props.marcacao.medico && (
                  <ModalSolutiOtp
                    name="solutiOtp"
                    toogle={this.toggleSolutiOtp}
                    isOpen={this.state.openSolutiOtp}
                    handleSetOtpCode={(otpCode) => {
                      this.handleSetOtpCode(otpCode);
                    }}
                  />
                )}
                {!parteFinal && (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.toogleParte();
                    }}
                  >
                    Avançar
                  </button>
                )}
              </div>
            </React.Fragment>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  agenda: { receiveAgenda },
}) => ({
  receiveAgenda: (agendas) => receiveAgenda({ agendas }),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { agendas } = state.agenda;
  const { client } = state.webSocket;

  return {
    user,
    agendas,
    client,
  };
}

export default connect(mapStateToProps, mapDispatch, null)(ModalImpressao);
