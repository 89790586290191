import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { ResponsiveBar } from '@nivo/bar';
import { BasicTooltip } from '@nivo/tooltip';
import { Md5 } from 'ts-md5';

/**
 * Props
 *
 */
type Props = {
  dataConvenio: DataConvenio[],
};

/**
 * State
 *
 */
type State = {
  finalDataConvenio: any[],
  keysConvenios: string[],
  hashProps: string,
  hasData: boolean,
};

/**
 * Interface Data Convenio
 *
 */
interface DataConvenio {
  name: string;
  value: number;
}

const BarTooltip: React.FunctionComponent<BarTooltipDatum> = (props) => {
  return (
    <BasicTooltip
      id={props.indexValue}
      value={props.value}
      color={props.color}
      enableChip
    />
  );
};

/**
 * Chart Dashboard Component
 *
 */
class ChartConvenioDashboardComponent extends React.Component<Props, State> {
  /**
   * Default constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.state = this.getInitialState();
  }

  /**
   * Responsible change componente.
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    let newHashProps: string = this.generateHasProps(this.props.dataConvenio);
    if (newHashProps !== prevState.hashProps) {
      this.setState(this.getInitialState());
    }
  }

  /**
   * Responsible get initial state
   *
   * @return {}
   */
  getInitialState(): any {
    const [finalDataConvenio, keys] = this.transformFinalDataConvenio(
      this.orderConvenioData(this.props.dataConvenio)
    );
    debugger;
    return {
      finalDataConvenio: finalDataConvenio,
      keysConvenios: keys,
      hashProps: this.generateHasProps(this.props.dataConvenio),
      hasData: this.hasData(),
    };
  }

  /**
   * Responsible generate hash.
   *
   * @param dataConvenio
   * @return {string}
   */
  generateHasProps = (dataConvenio: DataConvenio[]): string => {
    return Md5.hashStr(JSON.stringify(dataConvenio));
  };

  /**
   *
   * Responsible validate if has data.
   *
   * @return {boolean}
   */
  hasData = (): boolean => {
    return this.props.dataConvenio && this.props.dataConvenio.length > 0;
  };

  /**
   * Responsible order DataConvenio[]
   *
   * @param dataConvenio - dataConvenio
   * @returns {DataConvenio[]}
   */
  orderConvenioData(dataConvenio: DataConvenio[]): DataConvenio[] {
    if (dataConvenio) {
      return _.sortBy(dataConvenio, 'value');
    }
  }

  /**
   * Responsible transform DataConvenio[] to FinalDataConvenio[]
   *
   * @param dataConvenios - dataConvenio
   * @returns {boolean[]}
   */
  transformFinalDataConvenio(dataConvenios: DataConvenio[]): [any[], string[]] {
    console.log(dataConvenios);
    if (dataConvenios) {
      let finalDataConvenios: any = [];
      let keysConvenios = [];
      _.forEach(dataConvenios, function (dataConvenio: DataConvenio) {
        let finalDataConvenio = {};
        finalDataConvenio['id'] = dataConvenio.name;
        finalDataConvenio[dataConvenio.name] = dataConvenio.value;
        finalDataConvenio[dataConvenio.name + 'Color'] =
          '#' + Math.floor(Math.random() * 16777215).toString(16);
        keysConvenios.push(dataConvenio.name);
        finalDataConvenios.push(finalDataConvenio);
      });
      console.log([finalDataConvenios, keysConvenios]);
      return [finalDataConvenios, keysConvenios];
    } else {
      return [null, new Set()];
    }
  }

  /**
   * Render component
   *
   * @returns {JSX.Element}
   */
  render() {
    return (
      <React.Fragment>
        <Card className="chart-card-full bg-light">
          <Card.Body className="chart-card-body">
            <Card.Title className="chart-card-title d-flex justify-content-center">
              Convenios
            </Card.Title>
            {!this.state.hasData && (
              <React.Fragment>
                <div className="chart-text-blank justify-content-center">
                  Não existem dados a serem exibidos para essa pesquisa.
                </div>
              </React.Fragment>
            )}
            {this.state.hasData && (
              <React.Fragment>
                <div className="chart-card-full">
                  <ResponsiveBar
                    keys={_.uniq(this.state.keysConvenios)}
                    margin={{ top: 60, right: 550, bottom: 60, left: 80 }}
                    padding="0.2"
                    labelTextColor="inherit:darker(1.4)"
                    labelSkipHeight="16"
                    labelSkipWidth="16"
                    data={this.state.finalDataConvenio}
                    colors={{ scheme: 'red_blue' }}
                    height={500}
                    axisBottom={null}
                    borderWidth="1px"
                    borderColor="#c7c7c7"
                    legends={[
                      {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        toggleSerie: true,
                        justify: false,
                        translateX: 120,
                        translateY: this.state.finalDataConvenio > 15 ? 50 : 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    tooltip={BarTooltip}
                  />
                </div>
              </React.Fragment>
            )}
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

/**
 * Export default connect
 *
 */
export default connect(null, null, null)(ChartConvenioDashboardComponent);
