import _ from 'lodash';
import 'moment/locale/pt-br';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CabecalhoPaciente from './new/CabecalhoPaciente';
import ProntuarioForm from './new/ProntuarioForm';
import ProntuarioHistoricoForm from './new/ProntuarioHistoricoForm';

import moment from 'moment';
import Draggable from 'react-draggable';
import { scroller } from 'react-scroll';
import { objectsConstants } from '../../_constants/objects.constants';
import { urlsConstants } from '../../_constants/urls.constant';
import { ConfirmAcationService } from '../../_services/ConfirmAcationService';
import { marcacaoService } from '../../_services/marcacao.service';
import { medicoService } from '../../_services/medico.service';
import { pacienteService } from '../../_services/paciente.service';
import { prontuarioService } from '../../_services/prontuario.service';
import { triagemService } from '../../_services/triagem.service';
import { userService } from '../../_services/user.service';
import VideoConference from '../../components/Jitsi/VideoConference';
import { ModalConsultarProntuario } from '../../components/Modal/ModalConsultarProntuario';
import { ModalGerarDocumentos } from '../../components/Modal/ModalGerarDocumentos';
import { ModalProtocolo } from '../../components/Modal/ModalProtocolo';
import ButtonAutorized from '../../components/buttons/ButtonAutorized';
import CheckBoxInputOnly from '../../components/inputs/CheckBoxInputOnly';
import IconChamarPaciente from '../../images/icon-chamar-paciente.svg';
import IconSend from '../../images/icon-send-link.svg';
import {
  getAgendasDayPacienteMedico,
  updateMarcacao,
} from './../../_helpers/agenda.helper';
import AnamneseSintetico from './AnamneseSintetico';
import './ProntuarioPage.scss';

class ProntuarioPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      listAnamnese: [],
      // newAnamnese: false,
      entity: { paciente: {} },
      paciente: {},
      agendamentosHoje: [],
      consultaAvulsa: [],
      showProntuarioSintetico: false,
      showMeusPacientes: false,
      viewMode: false,
      openModalGerarDocumentos: false,
      openModalConsultarProntuario: false,
      showMeetConfrence: false,
      documentosGerados: [],
      triagem: null,
    };
  }

  componentDidMount() {
    if (
      !(
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.anamnese
      )
    ) {
      this.loadPacienteAndMedico();
    } else {
      let viewMode = true;
      let marcacao = this.props.location.state.anamnese;
      // let entity = _.set(marcacao, 'marcacao', marcacao);
      this.setState(
        {
          paciente: marcacao.paciente,
          entity: marcacao,
          viewMode,
          agendamentosHoje: [marcacao],
        },
        () => {
          // this.loadListAnamnese();
          // this.findAgendamentoHoje();
        }
      );
    }
    this.loadMarcacaoAvulsa();
  }

  loadPacienteAndMedico = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      if (userService.isMedico()) {
        Promise.all([
          pacienteService.doGet(this.props.match.params.id),
          medicoService.doGet(this.props.user.id),
          prontuarioService.findByMedicoAndPacienteHoje(
            this.props.user.id,
            this.props.match.params.id
          ),
        ]).then(
          (response) => {
            let paciente = response[0].data.data;
            let medico = response[1].data;
            let prontuarios = response[2].data;
            let entity;
            let viewMode = false;
            if (prontuarios && prontuarios.length > 0) {
              entity = prontuarios[0];
              entity.medico = medico;
              entity.paciente = paciente;
              entity.listDoencasPre = listDoencasPre;
              viewMode = true;
            } else {
              entity = { paciente, medico: medico, listDoencasPre };
            }
            let listDoencasPre = _.get(paciente, 'listDoencasPre', []);
            let agendamentosHoje = this.findAgendamentoHoje();
            if (
              (agendamentosHoje && agendamentosHoje.length > 0) ||
              this.props.match.params.idMarcacao
            ) {
              let idMarcacao = this.props.match.params.idMarcacao
                ? this.props.match.params.idMarcacao
                : agendamentosHoje[0].idMarcacao;
              marcacaoService.doGet(idMarcacao).then((response) => {
                let marcacao = response.data.data;
                console.log('marcacao');
                console.log(marcacao);
                let newEntity = _.cloneDeep(entity);
                _.set(newEntity, 'marcacao', marcacao);
                this.setState(
                  {
                    paciente,
                    entity: newEntity,
                    viewMode,
                    agendamentosHoje,
                  },
                  () => {
                    this.props.loading(false);
                    this.loadListAnamnese();
                    triagemService.findByMarcacao(idMarcacao).then((r) => {
                      this.setState({ triagem: r.data });
                    });
                    this.findAgendamentoHoje();
                  }
                );
              });
            } else {
              this.setState(
                {
                  paciente,
                  entity,
                  viewMode,
                  agendamentosHoje,
                },
                () => {
                  this.props.loading(false);
                  this.loadListAnamnese();

                  this.findAgendamentoHoje();
                }
              );
            }
          },
          (error) => {
            console.log(error);
            this.props.loading(false);
          }
        );
      } else {
        pacienteService.doGet(this.props.match.params.id).then(
          (response) => {
            let paciente = response.data.data;

            let listDoencasPre = _.get(paciente, 'listDoencasPre', []);
            this.setState(
              {
                paciente,
                entity: { paciente, medico: {}, listDoencasPre },
              },
              () => {
                this.props.loading(false);
                this.loadListAnamnese();

                // this.findAgendamentoHoje();
              }
            );
          },
          (error) => {
            console.log(error);
            this.props.loading(false);
          }
        );
      }
    }
  };

  loadMarcacaoAvulsa = () => {
    if (this.props.match.params.idMarcacao) {
      this.props.loading(true);
      marcacaoService
        .findHorarioConsultaByIdMarcacao(this.props.match.params.idMarcacao)
        .then(
          (response) => {
            let marcacaoAvulsa = response.data;
            this.setState({ consultaAvulsa: [marcacaoAvulsa] }, () => {
              this.props.loading(false);
            });
          },
          (error) => {
            console.log(error);
            this.props.loading(false);
          }
        );
    }
  };

  loadListAnamnese = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      prontuarioService.doList(this.props.match.params.id).then(
        (json) => {
          this.setState({ listAnamnese: json.data });
          this.props.loading(false);
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };

  getIdade = (dataNascimento) => {
    if (dataNascimento) {
      let arrIdade = dataNascimento.split('-');
      var d = new Date(),
        ano_atual = d.getFullYear(),
        mes_atual = d.getMonth() + 1,
        dia_atual = d.getDate(),
        ano_nasc = +arrIdade[0],
        mes_nasc = +arrIdade[1],
        dia_nasc = +arrIdade[2],
        idade = ano_atual - ano_nasc;
      if (
        mes_atual < mes_nasc ||
        (mes_atual === mes_nasc && dia_atual < dia_nasc)
      ) {
        idade--;
      }
      if (idade < 1) {
        return ' meses';
      }
      if (idade === 1) {
        return idade + ' ano';
      }
      if (idade > 1) {
        return idade + ' anos';
      }
    }
  };

  concluiAtendimento = (marcacao) => {
    let emAtendimento = this.getAtendimentoByStatus(
      objectsConstants.EM_ATENDIMENTO
    );
    if (emAtendimento) {
      this.handleStatusChanged(
        objectsConstants.ATENDIDO,
        emAtendimento,
        marcacao
      );
    }
    this.setState({ consultaAvulsa: [] });
  };

  handleSave = (values, marcacao) => {
    let _newEnntity = _.cloneDeep(this.state.entity);
    _newEnntity = values;
    this.setState({ entity: _newEnntity, marcacao }, () => {
      this.concluiAtendimento(marcacao);
    });
    this.loadListAnamnese();
  };

  findAgendamentoHoje = () => {
    const { agendas } = this.props;
    let paciente = { id: parseInt(this.props.match.params.id) };
    let medico = this.props.user;
    let now = new Date();
    let agendamentosHoje = getAgendasDayPacienteMedico(
      agendas,
      now,
      paciente,
      medico
    );
    if (agendamentosHoje) {
      return agendamentosHoje;
    }
  };

  podeCriarReceituario = () => {
    return true;
  };
  podeCriarAnamnese = () => {
    const { agendamentosHoje } = this.state;
    if (agendamentosHoje) {
      for (let i = 0; i < agendamentosHoje.length; i++) {
        if (
          agendamentosHoje[i].status === objectsConstants.EM_ATENDIMENTO ||
          agendamentosHoje[i].status ===
            objectsConstants.AGUARDANDO_ATENDIMENTO ||
          agendamentosHoje[i].status === objectsConstants.EM_CHAMADA ||
          (agendamentosHoje[i].status === objectsConstants.MARCADO &&
            agendamentosHoje[i].telemedicina)
        ) {
          return true;
        }
      }
    }
    return false;
  };

  // temAtendimentoParaChamar = () => {
  //   const { agendamentosHoje } = this.state;
  //   for (let i = 0; i < agendamentosHoje.length; i++) {
  //     if (
  //       agendamentosHoje[i].status === objectsConstants.AGUARDANDO_ATENDIMENTO
  //     ) {
  //       return true;
  //     }
  //   }

  //   return false;
  // };

  getAtendimentoParaChamada = () => {
    return this.getAtendimentoByStatus(objectsConstants.AGUARDANDO_ATENDIMENTO);
  };

  getAtendimentoByStatus = (status) => {
    const { agendamentosHoje } = this.state;
    for (let i = 0; i < agendamentosHoje.length; i++) {
      if (agendamentosHoje[i].status === status) {
        return agendamentosHoje[i];
      }
    }
    return null;
  };

  changeStatusAtendimento = (atendimentoParaChamada, status) => {
    let { agendamentosHoje } = _.cloneDeep(this.state);
    for (let i = 0; i < agendamentosHoje.length; i++) {
      if (
        agendamentosHoje[i].idMarcacao === atendimentoParaChamada.idMarcacao
      ) {
        agendamentosHoje[i].status = status;
      }
    }
    this.setState({ agendamentosHoje });
  };

  handleStatusChanged = (status: string, atendimento, marcacao) => {
    if (this.props.client.connected) {
      if (atendimento) {
        this.props.client.send(
          urlsConstants.SEND_PACIENTE_STATUS,
          JSON.stringify({ id: atendimento.idMarcacao, status })
        );
        this.changeStatusAtendimento(atendimento, status);
        let listMarcacaoHora = {};
        _.set(listMarcacaoHora, 'status', status);
        _.set(listMarcacaoHora, 'hora', _.get(marcacao, 'horario'));
        _.set(
          marcacao,
          'dataConsulta',
          _.get(marcacao, 'data')
            ? _.get(marcacao, 'data')
            : _.get(marcacao, 'dataConsulta')
        );
        _.set(marcacao, 'fkMedico', marcacao.medico.id);
        _.set(marcacao, 'fkEstabelecimento', marcacao.estabelecimento.id);
        _.set(marcacao, 'listMarcacaoHora', [listMarcacaoHora]);
        this.handleDataUpdate(marcacao);
      }
    }
  };

  atualizaLista = (name, checked) => {
    const { entity, showProntuarioSintetico, showMeusPacientes } = this.state;
    if (name == 'prontuarioSintetico') {
      this.setState({ showProntuarioSintetico: checked });
    } else if (name == 'somenteMeusPacientes') {
      this.setState({ showMeusPacientes: checked }, () => {});
    }
  };

  handleDataUpdate = (marcacao: any) => {
    let listAgenda = this.props.agendas;
    let agendas = updateMarcacao(listAgenda, marcacao);
    this.props.receiveAgenda(agendas);
  };

  scrollTo = (idSection) => {
    scroller.scrollTo(idSection, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  changeView = () => {
    this.setState({ viewMode: !this.state.viewMode });
  };
  toggleModalGerarDocumentos = () => {
    this.setState(({ openModalGerarDocumentos }) => ({
      openModalGerarDocumentos: !openModalGerarDocumentos,
    }));
  };
  toggleModalConsultarProntuario = () => {
    console.log(this.state);
    this.setState(({ openModalConsultarProntuario }) => ({
      openModalConsultarProntuario: !openModalConsultarProntuario,
    }));
  };
  userIsConected = (participantes, user) => {
    for (let i = 0; i < participantes.length; i++) {
      if (participantes[i].displayName === user.nome) {
        return true;
      }
    }
    return false;
  };
  participantLeft = (participantes) => {
    let medico = this.props.user;
    const { setTelemedicina } = this.props;
    if (this.userIsConected(participantes, medico)) {
      ConfirmAcationService.confirmMsg(
        () => {
          setTelemedicina({ nomeSala: null, showTelemedicina: false });
        },
        'Finalizar teleconsulta',
        <span>Deseja finalizar video chamada?</span>,
        <span className="text-danger">Chamada finalizada</span>,
        'Não',
        'Sim',
        objectsConstants.TYPE_WARNING
      );
    } else {
      setTelemedicina({ nomeSala: null, showTelemedicina: false });
    }
  };
  toggleProtocolo = () => {
    this.setState({ openProtocol: !this.state.openProtocol });
  };
  abreSalaTeleAtendimento(atendimento) {
    this.setState({ showMeetConfrence: true });
  }
  loadVideoConferencia = () => {
    const { user, showTelemedicina, nomeSala } = this.props;
    if (showTelemedicina && !this.props.viewMode) {
      return (
        <Draggable handle=".videocall">
          <div className={'card videocall d-flex flex-column'}>
            {' '}
            <VideoConference
              subject={'Tele Consulta'}
              participantLeft={this.participantLeft}
              roomName={nomeSala}
              userName={user.nome}
              userEmail={user.email}
            />{' '}
          </div>
        </Draggable>
      );
    }
    return null;
  };

  atualizaAnexosComModelo = (documentoVO) => {
    const { entity } = this.state;
    console.log(entity);
    let newEntity = _.cloneDeep(entity);
    if (newEntity.listAnexos) {
      newEntity.listAnexos.push(documentoVO);
    } else {
      newEntity.listAnexos = [documentoVO];
    }

    this.setState({ entity: newEntity });
  };

  render() {
    const {
      listAnamnese,
      showProntuarioSintetico,
      entity,
      showMeusPacientes,
      openModalConsultarProntuario,
    } = this.state;
    let listFilterAnamnese = [];
    const { ...otherProps } = this.props;
    if (showMeusPacientes) {
      listFilterAnamnese = _.filter(listAnamnese, function (a) {
        if (a.medico.id == entity.medico.id) {
          return true;
        }
        return false;
      });
    } else {
      listFilterAnamnese = _.cloneDeep(listAnamnese);
    }

    return (
      <React.Fragment>
        {entity.paciente && entity.marcacao && (
          <CabecalhoPaciente
            paciente={entity.paciente}
            agendamentosHoje={this.state.agendamentosHoje}
            marcacao={entity.marcacao}
          />
        )}
        {this.state.entity.marcacao && this.state.entity.marcacao.telemedicina && (
          <div className="box-folhas">
            <div className="cardInfo d-flex flex-lg-row flex-column align-items-center justify-content-between">
              <img
                src={IconChamarPaciente}
                alt="Imagem Link agendamento online"
              />
              <div className="">
                <p className="title">Teleconsulta</p>
                <p className="instruction">
                  O paciente está aguardando ser chamado na sala de espera,
                  chame o paciente para iniciar o atendimento.
                </p>
                <button
                  className="btn btn-sm btn-secondary"
                  title="Reenviar link de acesso"
                  onClick={() => {
                    this.sendEmail(this.state.entity);
                  }}
                >
                  <img
                    src={IconSend}
                    alt="Reenviar link de acesso por email"
                    className={'ma-icon'}
                  />
                  Reenviar link de acesso para o paciente
                </button>
                {/* <button
                      className="btn btn-sm btn-primary"
                      title="Copiar link"
                      onClick={() => {
                        this.copiaLink(atendimentoMarcado.nomeSalaPaciente);
                      }}

                      Copiar url da sala
                    </button> */}
              </div>
              <ButtonAutorized
                className="btn-primary save"
                label={'Iniciar teleconsulta'}
                role={objectsConstants.AGENDA_ATENDER}
                onClick={() => {
                  this.props.setTelemedicina({
                    nomeSala: this.state.entity.marcacao.nomeSala,
                    showTelemedicina: true,
                  });
                  this.abreSalaTeleAtendimento(this.state.entity.marcacao);
                  this.handleStatusChanged(
                    objectsConstants.EM_ATENDIMENTO,
                    this.state.entity,
                    this.state.entity.marcacao
                  );
                }}
              ></ButtonAutorized>
            </div>
          </div>
        )}

        {this.loadVideoConferencia()}
        {entity.marcacao &&
          (entity.marcacao.status == objectsConstants.EM_ATENDIMENTO ||
            entity.marcacao.status == objectsConstants.ATENDIDO ||
            entity.marcacao.status == objectsConstants.EM_CHAMADA ||
            (entity.marcacao.telemedicina &&
              entity.marcacao.status == objectsConstants.MARCADO)) && (
            <>
              {entity.marcacao.estabelecimento.prontuarioImportado && (
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={this.toggleModalConsultarProntuario}
                >
                  Consultar anamneses importadas
                </button>
              )}
              <ProntuarioForm
                triagem={this.state.triagem}
                toggleProtocolo={this.toggleProtocolo}
                user={this.props.user}
                toggleModalGerarDocumentos={this.toggleModalGerarDocumentos}
                toggleModalConsultarProntuario={
                  this.toggleModalConsultarProntuario
                }
                agendamentosHoje={this.state.agendamentosHoje}
                changeView={this.changeView}
                entity={this.state.entity}
                history={false}
                viewMode={this.state.viewMode}
              />
              <ModalConsultarProntuario
                loadListAnamnese={this.loadListAnamnese}
                name="listConsultarProntuario"
                toogle={this.toggleModalConsultarProntuario}
                isOpen={openModalConsultarProntuario}
                target={'ConsultarProntuarios'}
                user={this.props.user}
                load={this.props.loading}
                marcacao={entity.marcacao}
                entity={entity}
                callbackOnSave={(entity) => {}}
              />
              <ModalGerarDocumentos
                ref={(gerarDocumentos) =>
                  (this._gerarDocumentosInput = gerarDocumentos)
                }
                name="listGerarDocumentos"
                toogle={this.toggleModalGerarDocumentos}
                isOpen={this.state.openModalGerarDocumentos}
                target={'gerarDocumentos'}
                user={this.props.user}
                success={this.props.success}
                loading={this.props.loading}
                error={this.props.error}
                marcacao={entity.marcacao}
                entity={entity}
                callbackOnSave={(documentoVO) => {
                  this.atualizaAnexosComModelo(documentoVO);
                }}
              />
              <ModalProtocolo
                ref={(protocol) => (this._protocolInput = protocol)}
                name="listprotocol"
                toogle={this.toggleProtocolo}
                isOpen={this.state.openProtocol}
                target={'protocol'}
                user={this.props.user}
                entity={entity.prontuarioReceituario}
                success={this.props.success}
                loading={this.props.loading}
                error={this.props.error}
              />
            </>
          )}
        <div className="anamnese-anterior-topo mt-4">
          <div className="d-flex justify-content-center align-items-center consultas-container">
            <div className="consultas-container-texto m-0 ">
              Consultas anteriores
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center checkbox-container">
            <CheckBoxInputOnly
              name={`prontuarioSintetico`}
              id={'prontuarioSintetico'}
              className={'mt-2'}
              label={`Prontuário sintético`}
              checked={this.state.showProntuarioSintetico}
              onChange={(checked, name) => {
                this.atualizaLista(name, checked);
              }}
            ></CheckBoxInputOnly>

            <CheckBoxInputOnly
              name={`somenteMeusPacientes`}
              id={'somenteMeusPacientes'}
              className={'mt-2'}
              label={`Somente meus atendimentos`}
              checked={this.state.showMeusPacientes}
              onChange={(checked, name) => {
                this.atualizaLista(name, checked);
              }}
            ></CheckBoxInputOnly>
          </div>
        </div>
        {listFilterAnamnese && listFilterAnamnese.length > 0 && (
          <React.Fragment>
            {listFilterAnamnese.map((itemProntuario, index) => {
              if (!showProntuarioSintetico) {
                return (
                  <ProntuarioHistoricoForm
                    key={index * 5}
                    agendamentosHoje={[]}
                    entity={itemProntuario}
                    viewMode={true}
                    history={true}
                  />
                );
              } else {
                return (
                  <AnamneseSintetico
                    id={`anamnese-${index}`}
                    key={itemProntuario.id + '-' + index}
                    entity={itemProntuario}
                    viewMode={true}
                    showAvatar={index === 0}
                    data={moment(itemProntuario.dataCadastro).toDate()}
                    onSave={this.handleSave}
                  />
                );
              }
            })}
          </React.Fragment>
        )}

        {!listFilterAnamnese ||
          (listFilterAnamnese.length === 0 && (
            <div className="d-flex flex-row w-100 align-items-center sem-consulta-container justify-content-center mt-2 mb-3">
              <div className="d-flex align-items-center justify-content-center sem-consulta">
                <p>Sem atendimento anterior.</p>
              </div>
            </div>
          ))}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  agenda: { receiveAgenda },
  anamnese: { setTelemedicina },
}) => ({
  receiveAgenda: (agendas) => receiveAgenda({ agendas }),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  setTelemedicina: (options) => setTelemedicina(options),
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { agendas } = state.agenda;
  const { client } = state.webSocket;
  const { showTelemedicina, nomeSala } = state.anamnese;

  return {
    user,
    agendas,
    client,
    showTelemedicina,
    nomeSala,
  };
}

export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(withRouter(ProntuarioPage));
