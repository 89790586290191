//@flow
import _ from "lodash";
import React from "react";
import Avatar from "../components/Avatar/Avatar";
import IntegrationActionButtons from "../components/buttons/IntegrationActionButtons";
import { colorConstants } from "../_constants/color.constant";
import { objectsConstants } from "../_constants/objects.constants";
import { dateHelper } from "./date.helper";
import { formatterHelper } from "./formatter.helper";

export const tableHelper = {
  renderCustom,
};

function renderCustom(row: any, extra: any, column: any) {
  let value = _.get(extra, column.path);
  if (column.isDateTime) {
    value = dateHelper.format(value);
  }
  if (column.isDate) {
    value = dateHelper.format(value, { mode: "DATE" });
  }
  if (column.isDateHour) {
    value = dateHelper.format(value, { mode: "DATE_TIME" });
  }
  if (column.isAddres) {
    if (value !== null && value !== undefined) {
      value =
        value.street + (", " + value.number) + (" - " + value.neighborhood);
    } else {
      value = "--";
    }
  }
  if (column.isAvatar) {
    let user = column.user ? _.get(extra, column.user) : extra;
    value = (
      <Avatar
        user={user}
        size={34}
        className={column.className}
        namePropsMidiaId={column.propsMidiaId}
        editable={false}
      />
    );
  }

  if (column.isIntegrationAction) {
    value = (
      <IntegrationActionButtons extra={extra} row={row} column={column} />
    );
  }

  if (column.yesNo) {
    value = value ? "Sim" : "Não";
  }
  if (column.isMonth) {
    value = objectsConstants.MONTH_ARRAY[value - 1];
  }
  if (column.cpfCnpj) {
    value = formatterHelper.cpfCnpjFormatter(value);
  }

  if (column.isMoney) {
    value = formatterHelper.formatMoney(parseFloat(value), 2, ",", ".");
  }

  if (column.isDecimal) {
    value = parseFloat(value).toFixed(1);
  }

  if (column.isColor) {
    switch (value) {
      case colorConstants.VERDE_1.code:
        value = colorConstants.VERDE_1.color;
        break;
      case colorConstants.VERDE_2.code:
        value = colorConstants.VERDE_2.color;
        break;
      case colorConstants.AMARELO.code:
        value = colorConstants.AMARELO.color;
        break;
      case colorConstants.AZUL.code:
        value = colorConstants.AZUL.color;
        break;
      case colorConstants.LARANJA.code:
        value = colorConstants.LARANJA.color;
        break;
      case colorConstants.VERMELHO.code:
        value = colorConstants.VERMELHO.color;
        break;
      default:
        value = value;
    }
  }

  let customizedClass = "";
  if (column.hasClassName) {
    customizedClass = _.get(extra, column.hasClassName);
  }
  if (column.className) {
    customizedClass = column.className;
  }

  if (column.isStatus) {
    customizedClass = customizedClass + " " + _.get(extra, column.isStatus);
  }
  if (column.isStatusIntegracao) {
    switch (value) {
      case "SUCESS":
        value = "Sucesso";
        break;
      case "ERROR_TIMEOUT":
        value = "Erro - Tempo limite ultrapassado";
        break;
      case "ERROR_AUTHENTICATION":
        value = "Erro - Autenticação";
        break;
      case "ERROR_VALIDATION":
        value = "Erro - Dados inválidos";
        break;
      case "ERROR_NETWORK":
        value = "Erro - Conexão";
        break;
      case "ERROR_REFUSED":
        value = "Erro - Recusada pelo receptor";
        break;
      case "ERROR_UNKNOWN":
        value = "Erro - Desconhecido";
        break;
      case "PENDING":
        value = "Aguarde - Transação pendente";
        break;
      case "IN_PROCESS":
        value = "Aguarde - Em processamento";
        break;
    }
  }

  if (column.isStatusVoucher) {
    switch (value) {
      case "ENABLE":
        value = "Ativo";
        break;
      case "BLOCKED":
        value = "Bloqueado";
        break;
      case "USED":
        value = "Utilizado";
        break;
      case "OVERDUE":
        value = "Expirado";
        break;
      case "CANCELED":
        value = "Cancelado";
        break;
    }
  }


  if (column.isTipoNota) {
    switch (value) {
      case "0":
        value = "Nota fiscal";
        break;
      case "1":
        value = "Manual";
        break;

    }
  }

  if (column.isTipoSaida) {
    switch (value) {
      case "VENDA":
        value = "Venda";
        break;
      case "AJUSTE":
        value = "Ajuste";
        break;
      case "CONSUMO_INTERNO":
        value = "Consumo interno";
        break;
      case "TRANSFERENCIA":
        value = "Transferência";
        break;
      case "OUTRAS_SAIDAS":
        value = "Outras saídas";
        break;
    }
  }

  if (column.isTipoEntrada) {
    switch (value) {
      case "COMPRA":
        value = "Compra";
        break;
      case "AJUSTE":
        value = "Ajuste";
        break;
      case "DEVOLUCAO_VENDA":
        value = "Devolução venda";
        break;
      case "TRANSFERENCIA":
        value = "Transferência";
        break;
      case 'SALDO_INICIAL':
        value = 'Saldo inicial';
        break;
      case "OUTROS":
        value = "Outras entradas";
        break;
    }
  }

  if (column.isStatusMarcacao) {
    switch (value) {
      case "MARCADO":
        value = "Marcado";
        break;
      case "ATENDIDO":
        value = "Atendido";
        break;
      case "CANCELADO":
        value = "Cancelado";
        break;
      case "AGUARDANDO_ATENDIMENTO":
        value = "Aguardando atendimento";
        break;
      case "EM_ATENDIMENTO":
        value = "Em atendimento";
        break;
      case "EM_CHAMADA":
        value = "Em chamada";
        break;
      case "MARCACOES_FORA_HORARIO_MEDICO":
        value = "Fora do horário";
        break;
      case "NAO_COMPARECEU":
        value = "Não compareceu";
        break;
      case "AGUARDANDO_RECEPCAO":
        value = "Aguardando recepção";
        break;
      case "REAGENDADA":
        value = "Reagendada";
        break;
    }
  }

  return (
    <div data-title={column.title} className={customizedClass}>
      {value ? value : "--"}
    </div>
  );
}
