//@flow
import _ from 'lodash';
import 'moment/locale/pt-br';
import moment from 'moment/moment';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Client } from 'webstomp-client';
import { CacheAgenda } from '../../_components/CacheAgenda';
import { objectsConstants } from '../../_constants/objects.constants';
import { dateHelper } from '../../_helpers/date.helper';
import { translate } from '../../_helpers/messages.helper';
import { agendaService } from '../../_services/agenda.service';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { filaService } from '../../_services/fila.service';
import { marcacaoService } from '../../_services/marcacao.service';
import { whatsappService } from '../../_services/whatsapp.service';
import type { medico } from '../../_types/medico.type';
import BarraFiltro from '../../components/BarraFiltro/BarraFiltro';
import { ModalConsultaAvulsa } from '../../components/Modal/ModalConsultaAvulsa';
import { ModalCrud } from '../../components/Modal/ModalCrud';
import { ModalImpressaoAgenda } from '../../components/Modal/ModalImpressaoAgenda';
import { ModalWhatsapp } from '../../components/Modal/ModalWhatsapp';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../components/Topo/TopoListagem';
import { Calendar } from '../../components/calendar/Calendar';
import { CalendarDateView } from '../../components/calendar/CalendarDateView';
import CheeseburgerMenu from '../../components/menu/CheeseburgerMenu';
import Historico from '../Historico/Historico';
import MarcacaoForm from '../marcacao/MarcacaoForm';
import { urlsConstants } from './../../_constants/urls.constant';
import { filterMarcacoes } from './../../_helpers/agenda.helper';
import { userService } from './../../_services/user.service';
import AgendaHorariosPage from './../agenda/AgendaHorariosPage';
import AgendaFiltro from './AgendaFiltro';
import Indisponibilidade from './Indisponibilidade/Indisponibilidade';

type Props = {
  medico: medico,
  dados: any,
  dispatch: any,
  client: Client,
  showDivMedico: boolean,
};

type State = {
  showModalImpressao: boolean,
  medicos: any,
  showConsultaAvulsa: boolean,
  showModalWhatsapp: boolean,
  qrCode: any,
  canal?: any,
};

const defaultPaciente = {
  nome: '',
  email: '',
  // cpfCnpj: '',
  // especialidade: null,
  telefones: [{}],
  tipoAtendimento: objectsConstants.TIPO_ATENDIMENTO_PARTICULAR,
  pacienteConvenios: [
    {
      convenio: {},
      plano: '',
      carteirinha: '',
      validadeCarteirinha: '',
    },
  ],
};

class AgendaPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    let dataAtual = moment();
    this.state = {
      filtro: props.filter ? props.filter : {},
      showModalImpressao: false,
      showConsultaAvulsa: false,
      showModalWhatsapp: false,
      qrCode: '',
    };
    moment.locale('pt-br');
  }
  componentDidMount() {
    if (
      !this.props.location ||
      !this.props.location.state ||
      !this.props.location.state.notUpdate
    ) {
      this.handleFilterAgenda(this.state.filtro);
    }
    this.updateCache(this.state.filtro);
    this.getCamposObrigatorios();
    if (userService.isAdministrador()) {
      this.verificaStatusWhatsapp();
    }
  }

  getCamposObrigatorios = () => {
    let idEstabelecimento = userService.getExtension();

    if (idEstabelecimento) {
      estabelecimentoService
        .findCamposObrigatorios(idEstabelecimento)
        .then((response) => {
          console.log(response);
          let camposObrigatorios = response.data;
          this.setState({ camposObrigatorios: camposObrigatorios });
        });
    } else {
      let medico = userService.getCurrentUser();
      estabelecimentoService
        .findCamposObrigatoriosProfissionais(_.get(medico, 'id'))
        .then((response) => {
          console.log(response);
          let camposObrigatorios = response.data;
          this.setState({ camposObrigatorios: camposObrigatorios });
        });
    }
  };

  verificaStatusWhatsapp = () => {
    estabelecimentoService.whatsappConfig().then((r) => {
      if (r.data) {
        whatsappService.checkStatus().then((response) => {
          if (response) {
            console.log(response);
            let status = response.data.status;
            console.log(status);
            if (!status) {
              console.log('whatsapp disconnect');
              this.conectarWhatsapp();
            }
          }
        });
      }
    });
  };

  conectarWhatsapp = () => {
    whatsappService.startSession().then((response) => {
      console.log(response);
      // let qrCode = response.data.qrcode;
      let qrCode = response.data.qrcode;
      if (qrCode) {
        this.setState({ qrCode }, () => {
          this.toggleModalWhatsapp();
        });
      }
    });
  };

  gerarQrCode = () => {
    whatsappService.startSession().then((response) => {
      console.log(response);
      // let qrCode = response.data.qrcode;
      let qrCode = response.data.qrcode;
      this.setState({ qrCode }, () => {});
    });
  };
  toggleModalWhatsapp = () => {
    this.setState(({ showModalWhatsapp }) => ({
      showModalWhatsapp: !showModalWhatsapp,
    }));
  };

  toggleConsultaAvulsa = () => {
    this.setState(({ showConsultaAvulsa }) => ({
      showConsultaAvulsa: !showConsultaAvulsa,
    }));
  };

  updateCache = () => {
    const { year, month, tipoAgenda } = this.props;

    this.props.fechaFiltro();
    for (let i = 1; i <= 4; i++) {
      let filtro = this.getFiltroCache(
        objectsConstants.TIPO_AGENDA[0].name,
        month + i,
        year
      );
      let filtroExame = this.getFiltroCache(
        objectsConstants.TIPO_AGENDA[1].name,
        month + i - 1,
        year
      );
      agendaService.findHorariosConsulta(filtro).then((json) => {
        this.props.addCache(json.data, filtro);
      });
      agendaService.findHorariosConsulta(filtroExame).then((json) => {
        this.props.addCache(json.data, filtroExame);
      });
    }
  };

  getFiltroCache = (tipoAgenda, month, year) => {
    let filtro = {};
    let now = new Date();
    filtro.tipoAgenda = tipoAgenda;
    if (!userService.isMedico()) {
      filtro.estabelecimento = { id: userService.getExtension() };
    }
    let dataStart = new Date(year, month, 1, 0, 0, 0);
    let dataEnd = new Date(year, month + 1, 0, 23, 59, 59);
    filtro.dataStart = moment(dataStart).format('YYYY-MM-DDTHH:mm:ss');
    filtro.dataEnd = moment(dataEnd).format('YYYY-MM-DDTHH:mm:ss');
    return filtro;
  };

  componentWillUnmount() {
    this.props.fechaHistorico();
    this.props.fechaIndisponibilidade();
    this.props.fechaFiltro();
  }

  handleDateClick = (dataAtualAgenda, maxMarcacaoDia, viewHorario = true) => {
    this.props.receiveDadosAgenda(maxMarcacaoDia, dataAtualAgenda, viewHorario);
  };

  handleStatusChanged = (
    idMarcacao: number,
    status: string,
    tipoAtendimento: string,
    convenio: any,
    tipoConsulta: any,
    observacoes: string,
    especialidade: any,
    medico,
    etapaAtendimento,
    dadosAutorizacao,
    senhaAtendimento,
    listMidias,
    telemedicina
  ) => {
    let marcacao = {
      id: idMarcacao,
      status,
      tipoAtendimento,
      convenio,
      tipoConsulta,
      observacoes,
      especialidade,
      medico,
      etapaAtendimento,
      senhaAtendimento,
      listMidias,
      telemedicina,
    };
    if (dadosAutorizacao) {
      marcacao = _.merge(marcacao, dadosAutorizacao);
    }
    if (this.props.client.connected) {
      this.props.client.send(
        urlsConstants.SEND_PACIENTE_STATUS,
        JSON.stringify(marcacao)
      );
    }
  };

  handleMarcacaoCreated = (entidade: any) => {
    marcacaoService.doSave(entidade).then(
      (response) => {
        if (entidade.tipo === objectsConstants.TIPO_MARCACAO) {
          this.props.success({
            message: `Paciente agendado com sucesso!`,
          });
        } else if (entidade.tipo === objectsConstants.TIPO_ENCAIXE) {
          this.props.success({
            message: `Encaixe de paciente efetuado com sucesso!`,
          });
        }
      },
      (erros) => {
        try {
          let response = erros.response.data;
          if (response && response.messages) {
            for (var i = 0; i < response.messages.length; i++) {
              let erroItem = response.messages[i];
              this.props.error({
                message: translate(erroItem.message.code),
              });
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    );
  };

  setAgendaDoDia = (agendaDoDia) => {
    this.props.receiveAgendaDoDia(agendaDoDia);
  };

  handleFilterAgenda = (filtro) => {
    this.props.loading(true);
    const { year, month, tipoAgenda } = this.props;
    this.props.fechaFiltro();
    let now = new Date();
    let dataStart = new Date(year, month, 1, 0, 0, 0);
    let dataEnd = new Date(year, month + 1, 0, 23, 59, 59);
    if (!userService.isMedico()) {
      filtro.estabelecimento = { id: userService.getExtension() };
      filaService.findPacientesFila().then((r) => {
        this.props.receiveFila(r.data);
      });
    }
    filtro.tipoAgenda = tipoAgenda;
    filtro.dataStart = moment(dataStart).format('YYYY-MM-DDTHH:mm:ss');
    filtro.dataEnd = moment(dataEnd).format('YYYY-MM-DDTHH:mm:ss');
    filtro.medico = _.get(filtro, 'estabelecimentoMedico.medico');
    filtro.medicoList = _.get(filtro, 'medicoList');
    this.setState({ filtro }, () => {
      this.props.changeFilter(filtro);
      let cache = new CacheAgenda(filtro);
      if (this.props.cache && cache.exist(this.props.cache)) {
        this.props.receiveAgenda(
          cache.exist(this.props.cache).filtrar(filtro),
          month,
          filtro
        );
        this.props.loading(false);
      } else {
        let filtroParam = _.cloneDeep(filtro);
        filtroParam.medico = null;
        filtroParam.medicoList = null;
        agendaService.findHorariosConsulta(filtroParam, this.props.cache).then(
          (json) => {
            this.props.loading(false);
            this.props.receiveAgenda(json.data, month, filtro);
          },
          (error) => {
            if (tipoAgenda === objectsConstants.TIPO_AGENDA[0].name) {
              this.props.error({
                message: 'Não foi possível atualizar a agenda de CONSULTAS.',
              });
            } else if (tipoAgenda === objectsConstants.TIPO_AGENDA[1].name) {
              this.props.error({
                message: 'Não foi possível atualizar a agenda de EXAMES.',
              });
              this.props.receiveAgenda({});
            }

            this.props.loading(false);
          }
        );
      }
    });
  };

  handleTipoAgenda = (name, value) => {
    this.props.receiveTipoAgenda(value).then((response) => {
      this.handleFilterAgenda({});
    });
  };

  toggleModalImpressao = () => {
    this.setState(({ showModalImpressao }) => ({
      showModalImpressao: !showModalImpressao,
    }));
  };

  imprimeAgenda = () => {
    this.props.loading(true);
    let filtroPrint = _.cloneDeep(this.state.filtro);
    _.set(
      filtroPrint,
      'data',
      moment(this.props.dataAtualAgenda).format('YYYY-MM-DD')
    );
    agendaService.imprimir(filtroPrint).then(
      (response) => {
        var base64 = _.get(response, 'data.data.data');
        let asciiString = atob(base64);
        let array = new Uint8Array(
          [...asciiString].map((char) => char.charCodeAt(0))
        );
        const file = new Blob([array], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.props.loading(false);
      },
      (error) => {
        this.props.loading(false);
      }
    );
  };

  handleSelectPacienteConsultaAvulsa = (values) => {
    let paciente = _.get(values, 'paciente');
    let horario = dateHelper.getHourMinuteNow();
    let estabelecimentoAtendimento = _.get(values, 'estabelecimentoMedico');
    let medico = _.get(estabelecimentoAtendimento, 'medico');
    let estabelecimento = _.get(estabelecimentoAtendimento, 'estabelecimento');
    this.props.loading(true);
    userService.doGet(paciente.id).then(
      (response) => {
        let tipoConsulta = _.get(paciente, 'tipoConsulta');
        let tipo = objectsConstants.TIPO_MARCACAO;
        let newPaciente = response.data.data;
        _.set(newPaciente, 'tipoConsulta', tipoConsulta);
        _.set(newPaciente, 'primeiraConsulta', false);
        _.set(newPaciente, 'primeiraConsultaDisabled', false);
        _.set(newPaciente, 'horario', horario);
        _.set(newPaciente, 'atendeTelemedicina', true);
        _.set(newPaciente, 'tipo', tipo);
        _.set(newPaciente, 'requiredAddres', true);
        estabelecimentoService
          .findCamposObrigatorios(estabelecimento.id)
          .then((response) => {
            console.log(response);
            let camposObrigatorios = response.data;
            _.set(newPaciente, 'camposObrigatorios', camposObrigatorios);
            console.log('paciente da avulsa');
            console.log(newPaciente);
            this.setState(
              {
                paciente: newPaciente,
                showPacienteConsultaAvulsa: true,
                horaNewPaciente: horario,
                medico,
                estabelecimentoAtendimento,
                estabelecimento,
                novoStatus: null,
              },
              () => {}
            );
            this.props.loading(false);
          });
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );
  };

  handleNewOptionClickConsultaAvulsa = (values) => {
    let paciente = defaultPaciente;
    paciente.nome = _.get(values, 'nome');
    paciente.primeiraConsulta = true;
    paciente.horario = dateHelper.getHourMinuteNow();
    paciente.tipo = objectsConstants.TIPO_MARCACAO;
    _.set(paciente, 'requiredAddres', true);
    let estabelecimentoAtendimento = _.get(values, 'estabelecimentoMedico');
    let medico = _.get(estabelecimentoAtendimento, 'medico');
    let estabelecimento = _.get(estabelecimentoAtendimento, 'estabelecimento');
    let cObrigatorio = _.filter(this.props.camposObrigatorios, function (co) {
      return co.idEstabelecimento == estabelecimento.id;
    });
    _.set(paciente, 'camposObrigatorios', cObrigatorio[0]);

    this.setState({
      paciente,
      showPacienteConsultaAvulsa: true,
      nameNewPaciente: paciente.nome,
      horaNewPaciente: paciente.horario,
      medico,
      estabelecimentoAtendimento,
      estabelecimento,
    });
  };
  cancelShowPacienteConsultaAvulsa = () => {
    let state = _.cloneDeep(this.state);
    state.showPacienteConsultaAvulsa = false;
    state.novoStatus = null;
    state.agendamento = null;
    this.setState(state);
    this.togglePacientesConsultaAvulsa();
  };

  togglePacientesConsultaAvulsa = () => {
    this.setState(({ openPacienteConsultaAvulsa }) => ({
      openPacienteConsultaAvulsa: !openPacienteConsultaAvulsa,
    }));
  };

  handleSavePacienteAndChangeConsultaAvulsa = (paciente) => {
    const { medico, estabelecimentoAtendimento, horaNewPaciente, agendamento } =
      this.state;
    let dataConsulta = moment(this.props.date);
    let fkCovenio = null;
    let planoConvenio = null;
    if (
      _.get(paciente, 'tipoAtendimento') ===
      objectsConstants.TIPO_ATENDIMENTO_CONVENIO
    ) {
      fkCovenio = _.get(paciente, 'pacienteConvenios[0].convenio.id');
      planoConvenio = _.get(paciente, 'pacienteConvenios[0].planoConvenio');
    }

    let tipoConsulta = _.get(paciente, 'tipoConsulta');
    let observacoes = _.get(paciente, 'observacoes');
    let tipo = _.get(paciente, 'tipo');

    marcacaoService
      .doSave({
        horaString: horaNewPaciente,
        dataConsulta: dataConsulta.format('YYYY-MM-DD'),
        fkPaciente: paciente.id,
        fkMedico: _.get(medico, 'id', _.get(paciente, 'medico.id')),
        fkEstabelecimentoAtendimento: estabelecimentoAtendimento.id,
        tipoAtendimento: paciente.tipoAtendimento,
        fkCovenio,
        paciente,
        telemedicina: paciente.telemedicina,
        tipoConsulta,
        observacoes,
        tipo,
        planoConvenio,
        especialidade: paciente.especialidade,
        consultaAvulsa: true,
      })
      .then(
        (response) => {
          let marcacaoResponse = response.data.data;
          this.handleStatusChanged(
            marcacaoResponse.id,
            objectsConstants.EM_ATENDIMENTO,
            paciente.tipoAtendimento,
            paciente.convenio,
            paciente.tipoConsulta,
            observacoes
          );
          this.props.setPaciente(paciente);
          this.togglePacientesConsultaAvulsa();
          this.handleAnamneseClick(paciente.id, marcacaoResponse.id);
        },
        (erros) => {
          console.log(erros);
          this.props.error({ message: `Erro ao atender fora da agenda` });
        }
      );

    // this.props.setPaciente(paciente);
    // this.togglePacientesConsultaAvulsa();
    // this.handleAnamneseClick(paciente.id);
  };

  handleNewOptionClickConsultaAvulsa = (values) => {
    let paciente = defaultPaciente;
    paciente.nome = _.get(values, 'nome');
    paciente.primeiraConsulta = true;
    paciente.horario = dateHelper.getHourMinuteNow();
    paciente.tipo = objectsConstants.TIPO_MARCACAO;
    _.set(paciente, 'requiredAddres', true);
    let estabelecimentoAtendimento = _.get(values, 'estabelecimentoMedico');
    let medico = _.get(estabelecimentoAtendimento, 'medico');
    let estabelecimento = _.get(estabelecimentoAtendimento, 'estabelecimento');
    let cObrigatorio = _.filter(this.props.camposObrigatorios, function (co) {
      return co.idEstabelecimento == estabelecimento.id;
    });
    _.set(paciente, 'camposObrigatorios', cObrigatorio[0]);

    this.setState({
      paciente,
      showPacienteConsultaAvulsa: true,
      nameNewPaciente: paciente.nome,
      horaNewPaciente: paciente.horario,
      medico,
      estabelecimentoAtendimento,
      estabelecimento,
    });
  };

  handleAnamneseClick = (idPaciente, idMarcacao) => {
    if (idMarcacao) {
      this.props.history.push('/prontuario/' + idPaciente + '/' + idMarcacao);
    } else {
      this.props.history.push('/prontuario/' + idPaciente);
    }
  };

  render() {
    const { ...otherProps } = this.props;
    return (
      <div className="px-0 px-xl-4">
        {!this.props.viewHorario && (
          <TopoTitleComponente
            mainTitle={
              this.props.tipoAgenda === objectsConstants.TIPO_AGENDA_CONSULTAS
                ? objectsConstants.TITULO_PAGINA_AGENDA
                : objectsConstants.TITULO_PAGINA_AGENDA_EXAMES
            }
            subTitle=""
            canBack={false}
          />
        )}
        {/* //topo dados User Logado */}
        <TopoListagem
          showIndisponibilidade={
            userService.isMedico() ||
            userService.isAdministrador() ||
            userService.isSecretaria() ||
            userService.isSuporte()
          }
          toggleConsultaAvulsa={this.toggleConsultaAvulsa}
          showfilter={true}
          showTipoAgenda={!this.props.viewHorario}
          showPrintButton={true}
          showAtenderForaAgenda={true}
          toggleModalImpressao={this.toggleModalImpressao}
          handleTipoAgenda={this.handleTipoAgenda}
          tipoAgenda={this.props.tipoAgenda}
          viewHorario={this.props.viewHorario}
        ></TopoListagem>
        <ModalConsultaAvulsa
          toogle={this.toggleConsultaAvulsa}
          isOpen={this.state.showConsultaAvulsa}
          handleSelectPacienteConsultaAvulsa={(values) => {
            this.handleSelectPacienteConsultaAvulsa(values);
          }}
          handleNewOptionClickConsultaAvulsa={(values) => {
            this.handleNewOptionClickConsultaAvulsa(values);
          }}
        />
        {this.state.showModalWhatsapp && (
          <ModalWhatsapp
            toogle={this.toggleModalWhatsapp}
            isOpen={this.state.showModalWhatsapp}
            qrCode={this.state.qrCode}
            gerarQrCode={this.gerarQrCode}
            verificaStatusWhatsapp={this.verificaStatusWhatsapp}
          />
        )}
        <ModalCrud
          title="Consulta fora da agenda"
          toogle={this.togglePacientesConsultaAvulsa}
          isOpen={this.state.showPacienteConsultaAvulsa}
          Componente={MarcacaoForm}
          agendamentoMode={true}
          viewMode={false}
          entity={this.state.paciente}
          callbackOnSave={this.handleSavePacienteAndChangeConsultaAvulsa}
          onCancel={this.cancelShowPacienteConsultaAvulsa}
          estabelecimento={this.state.estabelecimento}
          titleBtnSave={'Atender'}
          hideAddres={false}
          history={this.props.history}
          hideCarteirinha={this.state.novoStatus ? false : true}
          medico={this.state.medico}
          estabelecimentoAtendimento={this.state.estabelecimentoAtendimento}
          tipoAgenda={this.props.tipoAgenda}
          dataConsulta={this.props.date}
          consultaAvulsa={true}
          permissionSave={objectsConstants.AGENDA_MARCAR}
        ></ModalCrud>
        <CheeseburgerMenu
          isOpen={this.props.filtroAberto}
          closeCallback={this.props.fechaFiltro}
          right={true}
          width={400}
        >
          <AgendaFiltro
            user={userService.getCurrentUser()}
            changeFilter={this.handleFilterAgenda}
            filtro={this.state.filtro}
            fechaFiltro={this.props.fechaFiltro}
            tipoAgenda={this.props.tipoAgenda}
          />
        </CheeseburgerMenu>

        <CheeseburgerMenu
          isOpen={this.props.indisponibilidadeAberta}
          closeCallback={this.props.fechaIndisponibilidade}
          right={true}
          width={400}
        >
          {this.props.indisponibilidadeAberta && (
            <Indisponibilidade
              user={userService.getCurrentUser()}
              reloadAgenda={this.handleFilterAgenda}
              filtro={this.state.filtro}
              tipoAgenda={this.props.tipoAgenda}
            />
          )}
        </CheeseburgerMenu>

        <CheeseburgerMenu
          isOpen={this.props.historicoAberto ? true : false}
          closeCallback={this.props.fechaHistorico}
          right={true}
          width={400}
          className="historico-bg"
        >
          {this.props.historicoAberto && (
            <Historico idEntity={this.props.idEntityHistorico} />
          )}
        </CheeseburgerMenu>

        <div className="position-relative">
          {(this.state.filtro.especialidades ||
            this.state.filtro.medicoList ||
            this.state.filtro.convenio) && (
            <BarraFiltro
              remarcacao={false}
              limpaFiltro={this.handleFilterAgenda}
            ></BarraFiltro>
          )}
          {this.props.viewHorario ? (
            <React.Fragment>
              {/* <ModalConsultaAvulsa
                toogle={this.toggleConsultaAvulsa}
                isOpen={this.state.showConsultaAvulsa}
              /> */}

              <div className="d-flex flex-column flex-md-row justify-content-between lista-horarios">
                <div className="m-1 w-25 align-self-center">
                  {/* {dateHelper.isToday(this.props.dataAtualAgenda) &&
                    this.mountButtonOutOfSchedule()} */}
                </div>
                <div className="">
                  <CalendarDateView
                    data={this.props.dataAtualAgenda}
                    agendas={this.props.agendas}
                    onMonthChange={this.handleMonthChange}
                    arrowNavigation={true}
                    arrowClick={this.handleDateClick}
                    className="justify-content-between"
                  />
                </div>
                <div className="m-1 w-25"></div>
              </div>
              <div className="row">
                <AgendaHorariosPage
                  date={this.props.dataAtualAgenda}
                  maxMarcacaoDia={this.props.maxMarcacaoDia}
                  agendas={this.props.agendas}
                  onStatusChanged={this.handleStatusChanged}
                  onGoBack={this.handleOnGoBack}
                  goBackCache={this.OnGoBackCache}
                  limpaFiltro={this.handleFilterAgenda}
                  onMarcacaoCreated={this.handleMarcacaoCreated}
                  filtro={this.state.filtro}
                  year={this.props.year}
                  month={this.props.month}
                  tipoAgenda={this.props.tipoAgenda}
                  reagendamento={false}
                  camposObrigatorios={this.state.camposObrigatorios}
                  {...otherProps}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Calendar
                agendas={this.props.agendas}
                onClick={this.handleDateClick}
                onMonthChange={this.handleMonthChange}
                year={this.props.year}
                month={this.props.month}
              />
            </React.Fragment>
          )}
        </div>

        <ModalImpressaoAgenda
          ref={(impressaoAgenda) =>
            (this._impressaoAgendaInput = impressaoAgenda)
          }
          name="impressaoAgenda"
          toogle={this.toggleModalImpressao}
          filtro={this.state.filtro}
          isOpen={this.state.showModalImpressao}
          viewHorario={this.props.viewHorario}
          target={'impressaoAgenda'}
          user={this.props.user}
          load={this.props.loading}
          date={this.props.dataAtualAgenda ? this.props.dataAtualAgenda : null}
        />
      </div>
    );
  }

  handleOnGoBack = () => {
    let viewHorario = false;
    this.props.receiveViewHorario(viewHorario);
  };

  handleMonthChange = (year: any, month: any) => {
    this.props.receiveMonthAndYear(month, year).then((response) => {
      this.handleFilterAgenda(this.state.filtro);
    });
  };

  findMarcacoes = () => {
    return filterMarcacoes(this.props.dados, this.props.dataAtualAgenda);
  };
}

const mapDispatch = ({
  agenda: {
    addCache,
    receiveData,
    changeFilter,
    receiveAgenda,
    receiveDadosAgenda,
    receiveViewHorario,
    setFiltroRemarcacao,
    setCanalSocket,
    receiveMonthAndYear,
    receiveTipoAgenda,
    updateMarcacao,
    receiveFila,
  },
  agendaOnline: { setPaciente },
  load: { loading },
  filtromenu: { toggleFiltro, abreFiltro, fechaFiltro },
  indisponibilidademenu: {
    toggleIndisponibilidade,
    abreIndisponibilidade,
    fechaIndisponibilidade,
  },
  historicomenu: {
    toggleHistorico,
    abreHistorico,
    fechaHistorico,
    receiveidEntityHistorico,
  },
  alert: { success, error, warning },
}) => ({
  setCanalSocket: (canal) => setCanalSocket({ canal }),
  addCache: (agendas, filtro) => addCache({ agendas, filtro }),
  receiveAgenda: (agendas, month, filtro, source) =>
    receiveAgenda({ agendas, month, filtro, source }),
  receiveidEntityHistorico: (idEntityHistorico) =>
    receiveidEntityHistorico({ idEntityHistorico }),
  updateMarcacao: (marcacao) => updateMarcacao({ marcacao }),
  setFiltroRemarcacao: (filtroRemarcacao) =>
    setFiltroRemarcacao({ filtroRemarcacao }),
  receiveDadosAgenda: (maxMarcacaoDia, dataAtualAgenda, viewHorario) =>
    receiveDadosAgenda({
      maxMarcacaoDia,
      dataAtualAgenda,
      viewHorario,
    }),
  receiveFila: (fila) => receiveFila({ fila }),
  receiveMonthAndYear: (month, year) => receiveMonthAndYear({ month, year }),
  receiveTipoAgenda: (tipoAgenda) => receiveTipoAgenda({ tipoAgenda }),
  receiveViewHorario: (viewHorario) => receiveViewHorario({ viewHorario }),
  changeFilter: (filter) => changeFilter({ filter: filter }),
  receiveData: (data, medico) => receiveData({ data, medico }),
  loading: (load: boolean) => loading({ load }),
  toggleFiltro: () => toggleFiltro(),
  abreFiltro: () => abreFiltro({}),
  fechaFiltro: () => fechaFiltro(),
  toggleIndisponibilidade: () => toggleIndisponibilidade(),
  abreIndisponibilidade: () => abreIndisponibilidade({}),
  fechaIndisponibilidade: () => fechaIndisponibilidade(),
  toggleHistorico: () => toggleHistorico(),
  abreHistorico: () => abreHistorico({}),
  fechaHistorico: () => fechaHistorico(),
  success: (msg) => success(msg),
  error: (msg) => error(msg),
  warning: (msg) => warning(msg),
  setPaciente: (paciente) => setPaciente({ paciente }),
});

function mapStateToProps(state) {
  const {
    dados,
    medico,
    showDivMedico,
    filter,
    agendas,
    cache,
    maxMarcacaoDia,
    dataAtualAgenda,
    viewHorario,
    filtroRemarcacao,
    canal,
    month,
    year,
    tipoAgenda,
  } = state.agenda;
  const { filtroAberto } = state.filtromenu;
  const { indisponibilidadeAberta } = state.indisponibilidademenu;
  const { historicoAberto, idEntityHistorico } = state.historicomenu;
  const { webSocketConnected } = state.webSocket;
  return {
    cache,
    dados,
    medico,
    showDivMedico,
    filtroAberto,
    indisponibilidadeAberta,
    historicoAberto,
    filter,
    agendas,
    maxMarcacaoDia,
    dataAtualAgenda,
    viewHorario,
    filtroRemarcacao,
    webSocketConnected,
    canal,
    month,
    year,
    tipoAgenda,
  };
}

const connectedAgendaPage = connect(
  mapStateToProps,
  mapDispatch
)(withRouter(AgendaPage));
export { connectedAgendaPage as AgendaPage };
