//@flow

const URL: string = process.env.REACT_APP_CLIENT_URL_API || '';

export const urlsConstants = {
  USER_PUBLIC: URL + '/publico/user/',
  REGISTER_PUBLIC: URL + '/publico/register/',
  URL_MIDIAS: URL + '/publico/midias/',
  MIDIA_IMAGE: URL + '/public/midia/imagem/',
  SEND_IMAGE_CKEDITOR: URL + '/publico/midias/sendImageCKEditor',
  MIDIA_FILE: URL + '/public/midia/file/',
  OAUTH_TOKEN: URL + '/oauth/token',
  USUARIO_ME: URL + '/api/usuarios/me',
  USUARIO_CONFIGURACAO: URL + '/api/usuarios/configuracao',
  URL_DOMAIN: URL,
  WS_WEBSOCKET: URL + '/ws',
  WEBSOCKET_STOMP: URL + '/agenda',
  MARCACAO: URL + '/marcacao/',
  MARCACAO_PUBLIC: URL + '/publico/marcacao/',
  CONSELHO: URL + '/conselho/',
  COOPERATIVAS: URL + '/cooperativa/',
  PROCEDIMENTO: URL + '/procedimento/',
  CATEGORIA_DOCUMENTO: URL + '/api/categoriaDocumento/',
  EXAMES_SOLICITADOS: URL + '/api/exameSolicitados/',
  WEBSOCKET_STOMP_TASK: URL + '/task',

  PACIENTE_FINDALL: URL + '/api/paciente/findAll',
  PACIENTE: URL + '/api/paciente/',
  PACIENTE_PUBLIC: URL + '/publico/paciente/',
  PACIENTE_LIST: URL + '/api/paciente/list',
  PACIENTE_FIND: URL + '/api/paciente/find/',
  PACIENTE_FIND_NOME_CPF: URL + '/api/paciente/findByNomeOuCpf/',

  AGENDA_LIST: URL + '/api/marcacao/list',

  PENDING_NOTIFY_MESSAGES_LIST: URL + '/api/messagingPending/',

  SALAS_FINDALL: URL + '/api/local/findAll',
  CID: URL + '/api/cid/',
  MEDICO_FINDALL: URL + '/medico/findAll',
  MEDICO: URL + '/medico/',
  MEDICO_PUBLIC: URL + '/publico/medico/',
  MEDICO_LIST: URL + '/medico/list',
  MEDICO_FIND: URL + '/medico/find/',

  GUIA_SASCCONNECT: URL + '/api/sascconnect/',
  INTEGRACAO_LOTE: URL + '/api/integracaoLote/',
  INTEGRACAO_LOG: URL + '/api/integracaoLog/',
  INTEGRACAO_ATENDIMENTOS: URL + '/api/integracaoItemLote/',

  INDISPONIBILIDADE_FINDALL: URL + '/indisponibilidadeHorarios/findAll',
  INDISPONIBILIDADE: URL + '/indisponibilidadeHorarios/',
  INDISPONIBILIDADE_MARCACOES_AFETADAS:
    URL + '/indisponibilidadeHorarios/countMarcacoesParaCancelar',
  INDISPONIBILIDADE_LIST: URL + '/indisponibilidadeHorarios/list',
  INDISPONIBILIDADE_FIND: URL + '/indisponibilidadeHorarios/find/',

  RESERVAR_HORARIO_FINDALL: URL + '/horarioReservado/findAll',
  RESERVAR_HORARIO: URL + '/horarioReservado/',
  RESERVAR_HORARIO_LIST: URL + '/horarioReservado/list',
  RESERVAR_HORARIO_FIND: URL + '/horarioReservado/find/',

  MEDICAMENTO: URL + '/api/medicamento/',

  ESTABELECIMENTO_FINDALL: URL + '/api/estabelecimento/findAll',
  ESTABELECIMENTO: URL + '/api/estabelecimento/',
  ESTABELECIMENTO_PUBLICO: URL + '/publico/estabelecimento/',
  ESTABELECIMENTO_CHECKIN: URL + '/api/estabelecimento/checkInLocal',
  ESTABELECIMENTO_CHECKIN_RECEPCIONISTA:
    URL + '/api/estabelecimento/checkInRecepcionistaLocal',

  ESTABELECIMENTO_LIST: URL + '/api/estabelecimento/list',
  ESTABELECIMENTO_PAGAMENTO:
    URL + '/api/estabelecimento/pagamento/getPagamento',

  ESTABELECIMENTO_FIND: URL + '/api/estabelecimento/find/',
  ESTABELECIMENTO_FIND_LOCAL:
    URL + '/api/estabelecimento/findLocalAtendimento/',

  ESTABELECIMENTO_FIND_LOCAL_RECEPCAO:
    URL + '/api/estabelecimento/findLocalAtendimentoRecepcao/',

  PAGAMENTO: URL + '/api/pagamento/',

  EXAME_FINDALL: URL + '/api/exame/findAll',
  EXAME: URL + '/api/exame/',
  EXAME_LIST: URL + '/api/exame/list',
  EXAME_FIND: URL + '/api/exame/find/',

  MODELO_FINDALL: URL + '/api/documento/findAll',
  MODELO: URL + '/api/documento/',
  MODELO_LIST: URL + '/api/documento/list',
  MODELO_FIND: URL + '/api/documento/find/',

  MODELO_ANAMNESE_FINDALL: URL + '/api/documento/findAll',
  MODELO_ANAMNESE: URL + '/api/documento/',
  MODELO_ANAMNESE_LIST: URL + '/api/documento/list',
  MODELO_ANAMNESE_FIND: URL + '/api/documento/find/',

  HISTORICO: URL + '/api/historico/',
  RELATORIO_CONSULTAS_FINDALL: URL + '/api/relatorio/consultas/findAll',
  RELATORIO_CONSULTAS: URL + '/api/relatorio/consultas/',
  RELATORIO_CONSULTAS_LIST: URL + '/api/relatorio/consultas/list',
  RELATORIO_CONSULTAS_FIND: URL + '/api/relatorio/consultas/find/',

  ESTOQUE_POSICAO: URL + '/api/estoquePosicao/',
  ESTOQUE_MOVIMENTACAO: URL + '/api/estoqueMovimentacao/',

  RELATORIO_EXAMES: URL + '/api/relatorio/exames/',

  USUARIO_FINDALL: URL + '/api/usuario/findAll',
  USUARIO: URL + '/api/usuario/',
  USUARIO_LIST: URL + '/api/usuario/list',
  USUARIO_FIND: URL + '/api/usuario/find/',

  SECRETARIA_FINDALL: URL + '/api/secretaria/findAll',
  SECRETARIA: URL + '/api/secretaria/',
  SECRETARIA_LIST: URL + '/api/secretaria/list',
  SECRETARIA_FIND: URL + '/api/secretaria/find/',

  PRODUTO_FINDALL: URL + '/api/produto/findAll',
  PRODUTO: URL + '/api/produto/',
  PRODUTO_LIST: URL + '/api/produto/list',
  PRODUTO_FIND: URL + '/api/produto/find/',

  ESTOQUE_FINDALL: URL + '/api/estoque/findAll',
  ESTOQUE: URL + '/api/estoque/',
  ESTOQUE_LIST: URL + '/api/estoque/list',
  ESTOQUE_FIND_BY_NOME: URL + '/api/estoque/findProdutoByNome/',
  ESTOQUE_FIND: URL + '/api/estoque/find/',

  ESTOQUE_PRODUTO_FIND_BY_ID: URL + '/api/estoque/findProdutoById/',

  ESTOQUE_UNIDADE_FIND_BY_ID_PRODUTO:
    URL + '/api/estoque/findEstoqueUnidadeByEstoqueId/',

  ENTRADA_ESTOQUE_LOTES: URL + '/api/entradaEstoqueProduto/findLoteByProduto/',

  ENTRADA_ESTOQUE_PRODUTO_FIND_BY_NOME:
    URL + '/api/entradaEstoqueProduto/findProdutosEmEstoqueByNome/',

  ENTRADA_ESTOQUE_FINDALL: URL + '/api/entradaEstoque/findAll',
  ENTRADA_ESTOQUE: URL + '/api/entradaEstoque/',
  ENTRADA_ESTOQUE_LIST: URL + '/api/entradaEstoque/list',
  ENTRADA_ESTOQUE_FIND: URL + '/api/entradaEstoque/find/',

  SAIDA_ESTOQUE_FINDALL: URL + '/api/saidaEstoque/findAll',
  SAIDA_ESTOQUE: URL + '/api/saidaEstoque/',
  SAIDA_ESTOQUE_LIST: URL + '/api/saidaEstoque/list',
  SAIDA_ESTOQUE_FIND: URL + '/api/saidaEstoque/find/',

  ESTOCAGEM: URL + '/api/estocagem/',
  PRECIFICACAO: URL + '/api/precificacao/',
  PARAM_TRIAGEM: URL + '/api/triagemgruposelecao/',

  EMPRESA: URL + '/api/empresa/',
  PRONTUARIO_INTEGRACAO: URL + '/api/prontuarioIntegracao/',
  PRONTUARIO_LIST: URL + '/api/prontuario/findAll/',
  PRONTUARIO_ULTIMOS_EXAMES: URL + '/api/prontuario/findUltimosExames/',
  PRONTUARIO_IMPRIMIR: URL + '/api/prontuario/imprimir',
  PRONTUARIO_RECEITUARIO_IMPRIMIR: URL + '/api/receituario/imprimir',
  PRONTUARIO: URL + '/api/prontuario/',

  RECEITUARIO: URL + '/api/receituario/',
  PROTOCOLO: URL + '/api/modeloProntuarioReceituario/findByMedico/',
  PROTOCOLO_ATENDIMENTO_RECEITUARIO:
    URL + '/api/protocoloAtendimentoReceituario/',

  PROTOCOLO_ANAMNESE: URL + '/api/modeloProntuarioAnamnese/findByMedico/',
  PROTOCOLO_ATENDIMENTO_ANAMNESE: URL + '/api/protocoloAtendimentoAnamnese/',

  SUBSCRIBE_PACIENTE_STATUS_CHANGE: '/consultas/change/',
  SUBSCRIBE_MEDICO_CONSULTA_INDISPONIBILIDADE:
    '/consultas/updateAgenda/medico/',
  SUBSCRIBE_ESTABELECIMENTO_CONSULTA_INDISPONIBILIDADE:
    '/consultas/updateAgenda/estabelecimento/',
  SEND_PACIENTE_STATUS: '/app/consultas/status',
  SEND_MARCACAO_NEW: '/app/consultas/add',

  CONVENIO: URL + '/api/convenio/',
  PUBLIC_CONVENIO: URL + '/publico/convenio/',
  ESPECIALIDADES: URL + '/api/especialidade/',
  ESPECIALIDADES_PUBLIC: URL + '/publico/especialidade/',
  PERFIL: URL + '/perfil/',
  FABRICANTE: URL + '/api/fabricante/',
  FORNECEDOR: URL + '/api/fornecedor/',
  UNIDADE_PADRAO: URL + '/api/unidadePadrao/',
  GRUPO: URL + '/api/grupo/',
  AGENDA: URL + '/api/agenda/',
  AGENDA_PUBLIC: URL + '/publico/agenda/',
  DOENCA: URL + '/api/doenca/',
  POSOLOGIA: URL + '/api/posologia/',

  WHATSAPP_CHECK_STATUS: URL + '/whatsapp/check-status',
  WHATSAPP_START_SESSION: URL + '/whatsapp/startSession',
  WHATSAPP_STOP_SESSION: URL + '/whatsapp/disconnect',

  TASK_BY_FILTER: URL + '/task/findByFilter',
  TASK_BY_USER: '/task/user/',
  TASK_BY_EXTENSION: '/task/estabelecimento/',
  TASK_STATUS_CHANGE: '/app/task/status',
  REACT_APP_URL_JITSI_SERVER: process.env.REACT_APP_URL_JITSI_SERVER,
  JITSI_SERVER_BASE_URL: process.env.REACT_APP_JITSI_SERVER_BASE_URL,
  REACT_APP_URL_PAINEL_SASCCM: process.env.REACT_APP_URL_PAINEL_SASCCM,

  SIGNER_DOWNLOAD: URL + '/publico/signer/downloadMedicalDocumentSign/',

  VOUCHER_SOLUTI: URL + '/api/soluti/voucher',

  DASHBOARD: URL + '/api/medicalAppointmentData',
  SUBSCRIBE_PACIENTE_SEM_MARCACAO: '/consultas/fila/',
  TRIAGEM: URL + '/api/triagem/',

  PACOTE: URL + '/api/pacote/',
  ETAPA_ATENDIMENTO: URL + '/api/etapaAtendimento',
  CHAMAR_PACIENTE_GENIALTEC: URL + '/marcacao/chamar/senha/',
  CHAMAR_NOVAMENTE_PACIENTE_GENIALTEC: URL + '/marcacao/chamarNovamente/senha/',
  CANCELAR_PACIENTE_GENIALTEC: URL + '/marcacao/cancelar/senha/',
  INATIVAR_PACIENTE_GENIALTEC: URL + '/marcacao/inativar/senha/',

  SEND_TEST: URL + '/app/consultas/test',
  GENIALTEC: URL + '/api/genialtec/',
};
