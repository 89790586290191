//@flow
import * as _ from "lodash";
import { urlsConstants } from '../_constants/urls.constant';
import { oauthHeader, oauthHeaderJson } from '../_helpers/oauth-header';
import { defaultService } from './defaultService';


export const userService = {
  me,
  doGet,
  doSave,
  getCurrentUser,
  getColorDefault,
  isSecretaria,
  isAdministrador,
  isSuporte,
  isMedico,
  getPerfilAtual,
  findByFiltro,
  findRoles,
  getExtension,
  isSudo,
  isCode4,
  doDelete,
  esqueciSenha,
  esqueciSenhaAgendamentoOnline,
  updateRecoverPassword,
  isCurrent,
  findRolesByCpf,
  findUserRolesByCpf,
  findByEmpresaSudo,
  changePerfil,
  isPaciente,
  changeEstabelecimento,
  findByCpfCnpjOrName,
  updatePassword,
  isEnfermeira
};
function findRolesByCpf(cpf) {
  return defaultService.doGet(`${urlsConstants.USER_PUBLIC}findRoleByCpf/${cpf}`);
}

function findUserRolesByCpf(cpf) {
  return defaultService.doGet(`${urlsConstants.USER_PUBLIC}findUserRoleByCpf/${cpf}`);
}
function findRoles() {
  return defaultService.doGet(`${urlsConstants.USUARIO}findAll`);
}

function findByFiltro(options: Options, filter: any) {
  return defaultService.findByFiltro(`${urlsConstants.USUARIO}list`, options, filter);
}

const perfilClass = {
  PROTOCOLO: 'avatarPerfil_Protocolo', //Protocolo
  AUDITOR: 'avatarPerfil_Auditor', //Auditor
  CONSULTORIO: 'avatarPerfil_Consultorio', //Consultorio
  COOPERADO: 'avatarPerfil_Cooperado', //Cooperado
  ADMINISTRADOR: 'avatarPerfil_Admin', //Administrador
};

function doDelete(id) {
  return defaultService.doDelete(`${urlsConstants.USUARIO}${id}`);
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.USUARIO}${id}`);
}

function doSave(usuario: usuario) {
  return defaultService.doSave(urlsConstants.USUARIO, usuario);
}

function getPerfilAtual(user) {
  if (user.role) {
    return user.role.role.description;
  }
  return '--';
}

function getColorDefault(user, tipoPerfil) {
  // if (!user.usuarioPerfis && tipoPerfil) {
  //   return perfilClass[tipoPerfil];
  // }
  if (user.perfilAtual) {
    return perfilClass[user.perfilAtual.perfil.tipoPerfil];
  } else {
    // return user.usuarioPerfis.map((perfil) => {
    // return perfilClass[perfil.perfil.tipoPerfil];
    return 'avatarPerfil_Protocolo';
    // });
  }
}

function me() {
  const requestOptions = {
    method: 'GET',
    headers: oauthHeader()
  };
  return defaultService.doGet(urlsConstants.USUARIO_ME).then(response => {

    if (response.data.error === 'invalid_token') {
      return Promise.reject('invalid_token');
    }
    localStorage.setItem('user', JSON.stringify(response.data));
    return response;
  });
}

/*
function updateFoto(image) {
  return defaultService.doPost(`${urlsConstants.USER}updateFoto`, image);
}
*/

function getCurrentUser() {
  let token = localStorage.getItem('user');
  if (!token) {
    return null;
  }
  let user = JSON.parse(token);
  return user;
}

function isEnfermeira() {
  let user = localStorage.getItem("user");
  if (!user) {
    return false;
  }
  user = JSON.parse(user);
  return hasRole("ROLE_ENFERMEIRA", user);
}
function isSudo() {
  let user = localStorage.getItem("user");
  console.log(user);
  if (!user) {
    return false;
  }
  user = JSON.parse(user);
  return hasRole("ROLE_SUDO", user);
}
function isSuporte() {
  let user = localStorage.getItem("user");
  if (!user) {
    return false;
  }
  user = JSON.parse(user);
  return hasRole("ROLE_SUPORTE", user);
}
function isCode4() {
  let user = localStorage.getItem("user");
  if (!user) {
    return false;
  }
  user = JSON.parse(user);
  return hasRole("ROLE_CODE", user);
}

function isSecretaria() {
  let user = localStorage.getItem("user");
  if (!user) {
    return false;
  }
  user = JSON.parse(user);
  return hasRole("ROLE_SECRETARIA", user);
}

function isAdministrador() {
  let user = localStorage.getItem("user");
  if (!user) {
    return false;
  }
  user = JSON.parse(user);
  return hasRole("ROLE_ADMIN", user);
}

function isMedico() {
  let user = localStorage.getItem("user");
  if (!user) {
    return false;
  }
  user = JSON.parse(user);
  return hasRole("ROLE_MEDICO", user);
}

function isPaciente() {
  let user = localStorage.getItem("user");
  if (!user) {
    return false;
  }
  user = JSON.parse(user);
  return hasRole("ROLE_PACIENTE", user);
}

function esqueciSenha(cpfSend) {
  return defaultService.doPost(
    `${urlsConstants.USER_PUBLIC}recoverPassword/`, cpfSend);
}

function esqueciSenhaAgendamentoOnline(RecoverUserPasswordVO) {
  return defaultService.doPost(
    `${urlsConstants.USER_PUBLIC}recoverPasswordOnline/`, RecoverUserPasswordVO);
}

function updateRecoverPassword(values) {
  return defaultService.doPut(
    `${urlsConstants.USER_PUBLIC}updateRecoverPassword/`, values);
}
function updatePassword(values) {
  return defaultService.doPut(
    `${urlsConstants.USUARIO}updatePassword/`, values);
}

function getExtension() {
  let user = localStorage.getItem("user");
  if (!user) {
    return false;
  }
  user = JSON.parse(user);
  return _.get(user, 'role.extension');
}

function hasRole(roleName, user) {
  let hasRole = false;
  if (roleName === _.get(user, 'role.role.name')) {
    hasRole = true;
  }
  return hasRole;
}

function findByEmpresaSudo(idEmpresa) {
  return defaultService.doGet(`${urlsConstants.USUARIO}findByEmpresaSudo/${idEmpresa}`);
}

function isCurrent(user) {
  let currentUser = getCurrentUser();
  if (currentUser.id === user.id) {
    return true;
  }
  return false;
}

function changePerfil(cpfCnpj, roleId) {
  return defaultService.doGet(`${urlsConstants.USUARIO}changePerfil/${cpfCnpj}/${roleId}`);
}

function changeEstabelecimento(extension, role) {
  return defaultService.doGet(`${urlsConstants.USUARIO}changeEstabelecimento/${extension}`);
}

function findByCpfCnpjOrName(cpfCnpjOrName: string): Promise<any> {
  return fetch(
    `${urlsConstants.USUARIO}findByCpfCnpjOrName/${cpfCnpjOrName}`,
    {
      method: 'GET',
      headers: oauthHeaderJson(),
    }
  ).then((response) => {
    return response.json().then((data) => {
      return data.data;
    });
  });
}